import skills from "../data/skills.json";

const FreeRanks = (character) => {

    var freeRanks = [];
    function addFreeRank(skill, rank) {
        freeRanks[skill] = parseInt(freeRanks[skill] || 0) + parseInt(rank);
    }

    if (character.Species.SelectedSkills && character.Species.SelectedSkills[0]) {
        character.Species.SelectedSkills.forEach((skill) => {
            addFreeRank(skills.find((s) => skill === s.skill).Key, 1);
        });
    }

    if (character.Species.SkillModifiers && character.Species.SkillModifiers[0]) {
        character.Species.SkillModifiers.forEach((skill) => {
            addFreeRank(skill.Key, skill.RankStart);
        });
    }

    if (
        character.Species.SkillModifiers &&
        character.Species.SkillModifiers.Key
    ) {
        addFreeRank(
            character.Species.SkillModifiers.Key,
            character.Species.SkillModifiers.RankStart,
        );
    }

    if (character.Species.OptionChoices) {
        if (character.Species.OptionChoices[0]) {
            for (let oc in character.Species.OptionChoices) {
                let optionChoice = character.Species.OptionChoices[oc]
                if (optionChoice.Options && optionChoice.Options[0] && optionChoice.Options[0].SkillModifiers && optionChoice.Selected) {
                    addFreeRank(optionChoice.Options.find((o) => o.Key === optionChoice.Selected).SkillModifiers.Key, optionChoice.Options.find((o) => o.Key ===
                        optionChoice.Selected).SkillModifiers.RankAdd || optionChoice.Options.find((o) => o.Key === optionChoice.Selected).SkillModifiers.RankStart)
                }
            }
        } else if (character.Species.OptionChoices.Options[0] && character.Species.OptionChoices.Options[0].SkillModifiers && character.Species.OptionChoices.Selected) {
            let skillOption = character.Species.OptionChoices.Options.find((o) => o.Key === character.Species.OptionChoices.Selected)
            addFreeRank(skillOption.SkillModifiers.Key, skillOption.SkillModifiers.RankStart)
        }
    }

    if (
        character.Species.SubSpecies && character.Species.SubSpeciesList &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
            .SkillModifiers
    ) {
        addFreeRank(
            character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                .SkillModifiers.Key,
            character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                .SkillModifiers.RankStart,
        );
    }

    if (
        character.Species.SubSpeciesList &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies) &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
            .OptionChoices &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
            .OptionChoices[0] &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
            .OptionChoices[0].Selected &&
        character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
            .OptionChoices[0].Options.find((o) => o.Key ===
                character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                    .OptionChoices[0].Selected
            ).SkillModifiers
    ) {
        addFreeRank(
            character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                .OptionChoices[0].Options.find((o) => o.Key ===
                    character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                        .OptionChoices[0].Selected
                ).SkillModifiers.Key,
            character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                .OptionChoices[0].Options.find((o) => o.Key ===
                    character.Species.SubSpeciesList.find((ss) => ss.Key === character.Species.SubSpecies)
                        .OptionChoices[0].Selected
                ).SkillModifiers.RankStart,
        );
    }
    if (character.CareerRanks) {
        for (let r in character.CareerRanks) {
            let key = skills.find(
                (Skill) => Skill.skill === character.CareerRanks[r],
            ).Key;
            addFreeRank(key, 1);
        }
    }

    if (character.SpecRanks) {
        for (let r in character.SpecRanks) {
            let key = skills.find(
                (Skill) => Skill.skill === character.SpecRanks[r],
            ).Key;
            addFreeRank(key, 1);
        }
    }

    for (let g in character.Gear) {
        let item = character.Gear[g]
        if (item.Equipped && item.BaseMods) {
            if (item.BaseMods.Key) {
                if (skills.find(s => s.Key === item.BaseMods.Key) && item.BaseMods.Count) {
                    addFreeRank(item.BaseMods.Key, item.BaseMods.Count)
                }
            } else if (Array.isArray(item.BaseMods)) {
                for (let m in item.BaseMods) {
                    let mod = item.BaseMods[m]
                    if (skills.find(s => s.Key === mod.Key) && mod.Count) {
                        addFreeRank(mod.Key, mod.Count)
                    }
                }
            }
        }
    }

    for (let g in character.Armor) {
        let item = character.Armor[g]
        if (item.Equipped && item.BaseMods) {
            if (item.BaseMods.Key) {
                if (skills.find(s => s.Key === item.BaseMods.Key) && item.BaseMods.Count) {
                    addFreeRank(item.BaseMods.Key, item.BaseMods.Count)
                }
            } else if (Array.isArray(item.BaseMods)) {
                for (let m in item.BaseMods) {
                    let mod = item.BaseMods[m]
                    if (skills.find(s => s.Key === mod.Key) && mod.Count) {
                        addFreeRank(mod.Key, mod.Count)
                    }
                }
            }
        }
    }

    for (let g in character.Weapons) {
        let item = character.Weapons[g]
        if (item.Equipped && item.BaseMods) {
            if (item.BaseMods.Key) {
                if (skills.find(s => s.Key === item.BaseMods.Key) && item.BaseMods.Count) {
                    addFreeRank(item.BaseMods.Key, item.BaseMods.Count)
                }
            } else if (Array.isArray(item.BaseMods)) {
                for (let m in item.BaseMods) {
                    let mod = item.BaseMods[m]
                    if (skills.find(s => s.Key === mod.Key) && mod.Count) {
                        addFreeRank(mod.Key, mod.Count)
                    }
                }
            }
        }
    }


    return freeRanks;
};
export default FreeRanks;
