import { Paper, Typography, TextField, Grid, IconButton, Button, Box, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import weapons from '../data/weapons.json'
import qualitiesString from "../Util/QualitiesString";

const VehicleEdit = ({ editedItem, index, appFiles, setAppFiles }) => {
    const talents = JSON.parse(appFiles.talents)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["vehicles"]);
        newCat[index] = newItem;
        newFiles["vehicles"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    if (editedItem.BaseMods && !Array.isArray(editedItem.BaseMods)) { editedItem.BaseMods = [editedItem.BaseMods] }

    return (
        <Paper>
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Price"
                value={editedItem?.Price || 0}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Price: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Rarity"
                value={editedItem?.Rarity || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Rarity: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Crew"
                value={editedItem?.Crew || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Crew: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Passengers"
                value={editedItem?.Passengers || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Passengers: e.target.value })
                }
            />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <span>Navicomputer:</span>
                <Checkbox
                    checked={editedItem?.Navicomputer || false}
                    onChange={(e) => handleEdit({ ...editedItem, Navicomputer: e.target.checked })}
                />
            </Box>
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Primary Hyperdrive Class"
                value={editedItem?.HyperdrivePrimary || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, HyperdrivePrimary: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Backup Hyperdrive Class"
                value={editedItem?.HyperdriveBackup || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, HyperdriveBackup: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Sensor Range"
                value={editedItem?.SensorRange || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, SensorRange: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Hard Points"
                value={editedItem?.HP || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, HP: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Encumbrance Capacity"
                value={editedItem?.EncumbranceCapacity || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, EncumbranceCapacity: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Consumables"
                value={editedItem?.Consumables || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Consumables: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Speed"
                value={editedItem?.Speed || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Speed: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Handling"
                value={editedItem?.Handling || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Handling: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Silhouette"
                value={editedItem?.Silhouette || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Silhouette: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Armor"
                value={editedItem?.Armor || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Armor: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Defense Fore"
                value={editedItem?.DefFore || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, DefFore: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Defense Aft"
                value={editedItem?.DefAft || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, DefAft: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Defense Port"
                value={editedItem?.DefPort || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, DefPort: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Defense Starboard"
                value={editedItem?.DefStarboard || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, DefStarboard: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Hull Trauma Threshold"
                value={editedItem?.HullTrauma || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, HullTrauma: e.target.value })
                }
            />
            <TextField
                fullWidth
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="System Strain Threshold"
                value={editedItem?.SystemStrain || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, SystemStrain: e.target.value })
                }
            />
            <Typography>Base Mods:</Typography>
            {editedItem.BaseMods && (
                <Grid container>
                    {editedItem.BaseMods.map((mod, index) => (
                        <Grid item key={index} sx={{ margin: "7.5px" }}>
                            {(mod.MiscDesc || mod.MiscDesc === "") &&
                                <>
                                    <Typography>Custom Mod:</Typography>
                                    <TextField
                                        label="Description"
                                        sx={{ minWidth: "500px" }}
                                        value={mod.MiscDesc}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].MiscDesc = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            <IconButton onClick={(e) => {
                                let newMods = [...editedItem.BaseMods];
                                newMods.splice(index, 1)
                                handleEdit({ ...editedItem, BaseMods: newMods });
                            }}
                                sx={{ maxWidth: "30px" }}
                                size="small"
                                className="no-print"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods || []];
                newMods.push({ MiscDesc: "" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Custom Mod
            </Button>
            <Typography>Weapons:</Typography>
            {(editedItem.VehicleWeapons || editedItem.WeaponModifiers) && (
                <Grid container>
                    {editedItem.VehicleWeapons && editedItem.VehicleWeapons.map((weapon, index) => {
                        let lookupWeapon = {}
                        if (weapon.Key && !weapon.Name) {
                            lookupWeapon = weapons.find(w => w.Key === weapon.Key)
                        }
                        if (weapon.Qualities && Array.isArray(weapon.Qualities)) {
                            weapon.Qualities = qualitiesString(weapon.Qualities)
                        }
                        if (lookupWeapon.Qualities && Array.isArray(lookupWeapon.Qualities)) {
                            lookupWeapon.Qualities = qualitiesString(lookupWeapon.Qualities)
                        }

                        const handleWeaponChange = (newWeapon) => {
                            let newVehicleWeapons = [...editedItem.VehicleWeapons];
                            newVehicleWeapons.splice(index, 1);

                            let newWeaponModifiers = [...(editedItem.WeaponModifiers || [])];

                            let updatedWeapon = {
                                ...lookupWeapon,
                                ...newWeapon,
                            };
                            delete updatedWeapon.Key
                            newWeaponModifiers.push(updatedWeapon);

                            handleEdit({
                                ...editedItem,
                                VehicleWeapons: newVehicleWeapons,
                                WeaponModifiers: newWeaponModifiers,
                            });
                        };

                        return (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <TextField
                                    label="Name"
                                    sx={{ minWidth: "200px" }}
                                    value={weapon.Name || lookupWeapon.Name}
                                    onChange={(e) => {
                                        let newWeapon = { ...weapon, Name: e.target.value };
                                        handleWeaponChange(newWeapon);
                                    }}
                                />
                                <TextField
                                    label="Damage"
                                    sx={{ minWidth: "100px" }}
                                    value={weapon.Damage || lookupWeapon.Damage}
                                    onChange={(e) => {
                                        let newWeapon = { ...weapon, Damage: e.target.value };
                                        handleWeaponChange(newWeapon);
                                    }}
                                />
                                <TextField
                                    label="Crit"
                                    sx={{ minWidth: "100px" }}
                                    value={weapon.Crit || lookupWeapon.Crit}
                                    onChange={(e) => {
                                        let newWeapon = { ...weapon, Crit: e.target.value };
                                        handleWeaponChange(newWeapon);
                                    }}
                                />
                                <TextField
                                    label="Range"
                                    sx={{ minWidth: "100px" }}
                                    value={weapon.Range || lookupWeapon.Range}
                                    onChange={(e) => {
                                        let newWeapon = { ...weapon, Range: e.target.value };
                                        handleWeaponChange(newWeapon);
                                    }}
                                />
                                <TextField
                                    label="Qualities"
                                    sx={{ minWidth: "500px" }}
                                    value={weapon.Qualities || lookupWeapon.Qualities}
                                    onChange={(e) => {
                                        let newWeapon = { ...weapon, Qualities: e.target.value };
                                        handleWeaponChange(newWeapon);
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        let newWeapons = [...editedItem.VehicleWeapons];
                                        newWeapons.splice(index, 1);
                                        handleEdit({ ...editedItem, VehicleWeapons: newWeapons });
                                    }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        );
                    })}

                    {editedItem.WeaponModifiers && editedItem.WeaponModifiers.map((modWeapon, index) => {
                        if (modWeapon.Qualities && Array.isArray(modWeapon.Qualities)) {
                            modWeapon.Qualities = qualitiesString(modWeapon.Qualities)
                        }
                        return (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <TextField
                                    label="Name"
                                    sx={{ minWidth: "200px" }}
                                    value={modWeapon.Name}
                                    onChange={(e) => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers[index].Name = e.target.value;
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                />
                                <TextField
                                    label="Damage"
                                    sx={{ minWidth: "200px" }}
                                    value={modWeapon.Damage}
                                    onChange={(e) => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers[index].Damage = e.target.value;
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                />
                                <TextField
                                    label="Crit"
                                    sx={{ minWidth: "200px" }}
                                    value={modWeapon.Crit}
                                    onChange={(e) => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers[index].Crit = e.target.value;
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                />
                                <TextField
                                    label="Range"
                                    sx={{ minWidth: "200px" }}
                                    value={modWeapon.Range}
                                    onChange={(e) => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers[index].Range = e.target.value;
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                />
                                <TextField
                                    label="Qualities"
                                    sx={{ minWidth: "500px" }}
                                    value={modWeapon.Qualities}
                                    onChange={(e) => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers[index].Qualities = e.target.value
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        let newModifiers = [...editedItem.WeaponModifiers];
                                        newModifiers.splice(index, 1);
                                        handleEdit({ ...editedItem, WeaponModifiers: newModifiers });
                                    }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        )
                    })}
                </Grid>
            )}

            <Button onClick={(e) => {
                let newWeapons = [...editedItem.VehicleWeapons || []];
                newWeapons.push({})
                handleEdit({ ...editedItem, VehicleWeapons: newWeapons });
            }}
                className="no-print"
            >
                Add Weapon
            </Button>
        </Paper>
    )
}

export default VehicleEdit