import MarkedText from "./MarkedText";

function getDicePool(character, characteristic, skillRanks, skill, dieModifiers, weapon) {
    const greaterNum = Math.max(characteristic, skillRanks);
    const smallerNum = Math.min(characteristic, skillRanks);

    let profPool = "[PR]".repeat(smallerNum);
    let abPool = "[AB]".repeat(greaterNum - smallerNum);

    let mods = dieModifiers.find((m) => m.SkillKey === skill.Key) || {}

    if (weapon) {
        if (weapon.Qualities) {
            for (let q in weapon.Qualities) {
                let qual = weapon.Qualities[q]
                if (qual.Key) {
                    if (qual.Key === "ACCURATE") {
                        mods.BoostCount = mods.BoostCount ? mods.BoostCount + (qual.Count || 1) : (qual.Count || 1);
                    }
                    if (qual.Key === "INACCURATE") {
                        mods.AddSetbackCount ? mods.AddSetbackCount += (qual.Count || 1) : mods.AddSetbackCount = (qual.Count || 1)
                    }
                    if (qual.Key === "SUPERIOR" || qual.Key === "ADVADDCOM") {
                        mods.AdvantageCount ? mods.AdvantageCount += (qual.Count || 1) : mods.AdvantageCount = (qual.Count || 1)
                    }
                    if (qual.Key === "INFERIOR") {
                        mods.ThreatCount ? mods.ThreatCount += (qual.Count || 1) : mods.ThreatCount = (qual.Count || 1)
                    }
                    if (qual.Key === "SETBACKSUB") {
                        mods.SetbackSubCount ? mods.SetbackSubCount += (qual.Count || 1) : mods.SetbackSubCount = (qual.Count || 1)
                    }
                }
            }
            if (mods.AddSetbackCount && (mods.SetbackSubCount || mods.SetbackCount)) {
                while ((mods.AddSetbackCount > 0) && (mods.SetbackSubCount > 0)) {
                    mods.AddSetbackCount -= 1
                    mods.SetbackSubCount -= 1
                }
                while ((mods.AddSetbackCount > 0) && (mods.SetbackCount > 0)) {
                    mods.AddSetbackCount -= 1
                    mods.SetbackCount -= 1
                }
            }
        }
    }
    let modString = "";
    if (mods) {
        if (mods.UpgradeAbilityCount) {
            let i = 0;
            while (i < mods.UpgradeAbilityCount) {
                if (abPool !== "") {
                    abPool = abPool.slice(4);
                    profPool += "[PR]";
                } else {
                    abPool += "[AB]";
                }
                i++
            }
        }
        if (mods.BoostCount) {
            modString += "[BO]".repeat(mods.BoostCount);
        }
        if (mods.AddSetbackCount) {
            modString += "[SE]".repeat(mods.AddSetbackCount);
        }
        if (mods.SetbackSubCount || mods.SetbackCount) {
            modString += "[REMSETBACK]".repeat((mods.SetbackCount || 0) + (mods.SetbackSubCount || 0));
        }
        if (mods.ForceCount) {
            modString += "[FO]".repeat(character.ForceRating);
        }
        if (mods.SuccessCount) {
            modString += "[SU]".repeat(mods.SuccessCount);
        }
        if (mods.AdvantageCount) {
            modString += "[AD]".repeat(mods.AdvantageCount);
        }
        if (mods.FailureCount) {
            modString += "[FA]".repeat(mods.FailureCount);
        }
        if (mods.ThreatCount) {
            modString += "[TH]".repeat(mods.ThreatCount);
        }
    }
    return (
        <>
            <div
                style={{ padding: "15px" }}
                dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(profPool + abPool + modString),
                }}
            />
        </>
    );
}

export default getDicePool