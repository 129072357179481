import talents from "../data/talents.json"

function getFullBoughtTalents(character) {
  let universalForce = 0;
  let Bought = [];
  const talentMap = {};
  let talentData = {};
  if (character.Extras?.BattleScars && character.Extras.BattleScars[0]) {
    for (let m in character.Extras.BattleScars) {
      talentData = talents.find(
        (t) => t.Key === character.Extras.BattleScars[m].key,
      );
      talentMap[character.Extras.BattleScars[m].key] = talentData;
      Bought.push({
        key: character.Extras.BattleScars[m].key,
        data: talentMap[character.Extras.BattleScars[m].key],
        count: 1,
      });
    }
  }
  if (character.Species.TalentModifiers) {
    if (character.Species.TalentModifiers[0]) {
      for (let m in character.Species.TalentModifiers) {
        talentData = talents.find(
          (t) => t.Key === character.Species.TalentModifiers[m].Key,
        );
        talentMap[character.Species.TalentModifiers[m].Key] = talentData;
        Bought.push({
          key: character.Species.TalentModifiers[m].Key,
          data: talentMap[character.Species.TalentModifiers[m].Key],
          count: 1,
        });
      }
    } else if (character.Species.TalentModifiers.Key) {
      talentData = talents.find(
        (t) => t.Key === character.Species.TalentModifiers.Key,
      );
      talentMap[character.Species.TalentModifiers.Key] = talentData;
      Bought.push({
        key: character.Species.TalentModifiers.Key,
        data: talentMap[character.Species.TalentModifiers.Key],
        count: 1,
      });
    }
  } else if (
    character.Species.SubSpeciesList &&
    character.Species.SubSpeciesList.find(
      (ss) => (ss.Key === character.Species.SubSpecies),
    ) &&
    character.Species.SubSpeciesList.find(
      (ss) => (ss.Key === character.Species.SubSpecies),
    ).TalentModifiers
  ) {
    if (
      character.Species.SubSpeciesList.find(
        (ss) => (ss.Key === character.Species.SubSpecies),
      ).TalentModifiers[0]
    ) {
      for (let m in character.Species.SubSpeciesList[
        character.Species.SubSpecies
      ].TalentModifiers) {
        talentData = talents.find(
          (t) =>
            t.Key ===
            character.Species.SubSpeciesList.find(
              (ss) => (ss.Key === character.Species.SubSpecies),
            ).TalentModifiers[m].Key,
        );
        talentMap[
          character.Species.SubSpeciesList[
            character.Species.SubSpecies
          ].TalentModifiers[m].Key
        ] = talentData;
        Bought.push({
          key: character.Species.SubSpeciesList.find(
            (ss) => (ss.Key === character.Species.SubSpecies),
          ).TalentModifiers[m].Key,
          data: talentMap[
            character.Species.SubSpeciesList.find(
              (ss) => (ss.Key === character.Species.SubSpecies),
            ).TalentModifiers[m].Key
          ],
          count: 1,
        });
      }
    } else if (
      character.Species.SubSpeciesList.find(
        (ss) => (ss.Key === character.Species.SubSpecies),
      ).TalentModifiers.Key
    ) {
      talentData = talents.find(
        (t) =>
          t.Key ===
          character.Species.SubSpeciesList.find(
            (ss) => (ss.Key === character.Species.SubSpecies),
          ).TalentModifiers.Key,
      );
      talentMap[
        character.Species.SubSpeciesList.find(
          (ss) => (ss.Key === character.Species.SubSpecies),
        ).TalentModifiers.Key
      ] = talentData;
      Bought.push({
        key: character.Species.SubSpeciesList.find(
          (ss) => (ss.Key === character.Species.SubSpecies),
        ).TalentModifiers.Key,
        data: talentMap[
          character.Species.SubSpeciesList.find(
            (ss) => (ss.Key === character.Species.SubSpecies),
          ).TalentModifiers.Key
        ],
        count: 1,
      });
    }
  }
  for (let i in character.Specializations) {
    if (
      character.Specializations[i].Attributes &&
      character.Specializations[i].Attributes.ForceRating
    ) {
      universalForce = 1;
    }
    let specialization = character.Specializations[i];
    if (specialization.TalentRows) {
      specialization.TalentRows.forEach((row, rowIndex) => {
        row.Talents.forEach((talentKey, index) => {
          if (specialization.BoughtTalents[rowIndex][index]) {
            if (!talentMap[talentKey]) {
              const talentData = talents.find((t) => t.Key === talentKey);
              talentMap[talentKey] = talentData;
            }

            const existingTalent = Bought.find((t) => t.key === talentKey);
            if (existingTalent) {
              existingTalent.count++;
            } else {
              Bought.push({
                key: talentKey,
                data: talentMap[talentKey],
                count: 1,
              });
            }
          }
        });
      });
    }
  }
  return { Bought, universalForce };
}

export default getFullBoughtTalents