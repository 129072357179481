import { Paper, Typography, Grid, IconButton, Button, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import skills from '../data/skills.json'
import { SvgIcon } from '@mui/material';

const LeftRightIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 100 20"
        sx={{ width: "100px", height: "20px" }} >
        <rect width="100" height="20" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);

const UpDownIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 20 50"
        sx={{ width: "20px", height: "50px" }}
    >
        <rect width="20" height="50" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);


const SpecEdit = ({ editedItem, index, appFiles, setAppFiles }) => {

    const talents = JSON.parse(appFiles.talents)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["specializations"]);
        newCat[index] = newItem;
        newFiles["specializations"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    return (
        <Paper>
            {editedItem.CareerSkills && (
                <>
                    <Typography>Career Skills:</Typography>
                    <Grid container>
                        {editedItem.CareerSkills.map((cSkill, index) => (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <Select
                                    value={cSkill || ''}
                                    onChange={(e) => {
                                        let newSkills = [...editedItem.CareerSkills];
                                        newSkills[index] = e.target.value;
                                        handleEdit({ ...editedItem, CareerSkills: newSkills });
                                    }}
                                    sx={{ minWidth: "80px" }}
                                >
                                    {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                        <MenuItem key={skill.Key} value={skill.skill}>
                                            {skill.skill}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton onClick={(e) => {
                                    let newCareerSkills = [...editedItem.CareerSkills];
                                    newCareerSkills.splice(index, 1)
                                    handleEdit({ ...editedItem, CareerSkills: newCareerSkills });
                                }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                    <Button onClick={(e) => {
                        let newCareerSkills = [...editedItem.CareerSkills];
                        newCareerSkills.push("")
                        handleEdit({ ...editedItem, CareerSkills: newCareerSkills });
                    }}
                        className="no-print"
                    >
                        Add
                    </Button>
                </>
            )}
            {editedItem.TalentRows && (
                <>
                    <Typography>Talent Grid:</Typography>
                    {editedItem.TalentRows.map((row, index) => (
                        <Grid container key={index} spacing={2}>
                            {row.Talents.map((talent, i) => (
                                <Grid item xs={3} key={i}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{
                                                position: "relative",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            {i > 0 && (<Checkbox
                                                checked={row.Directions[i].Left && row.Directions[i - 1].Right}
                                                onChange={() => {
                                                    let newRows = [...editedItem.TalentRows];
                                                    newRows[index].Directions[i].Left = !newRows[index].Directions[i].Left;
                                                    newRows[index].Directions[i - 1].Right = !newRows[index].Directions[i - 1].Right;
                                                    handleEdit({ ...editedItem, TalentRows: newRows });
                                                }}
                                                icon={<LeftRightIcon />}
                                                checkedIcon={<LeftRightIcon checked />}
                                                sx={{
                                                    position: "absolute",
                                                    left: "18px",
                                                }}
                                            />
                                            )}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container direction="column" alignItems="center">
                                                <Grid item>
                                                    <Select
                                                        value={talent}
                                                        onChange={(e) => {
                                                            let newRows = [...editedItem.TalentRows];
                                                            let newRow = { ...row };
                                                            let newTalents = newRow.Talents;
                                                            newTalents[i] = e.target.value;
                                                            newRow.Talents = newTalents;
                                                            newRows[index] = newRow;
                                                            handleEdit({ ...editedItem, TalentRows: newRows });
                                                        }}
                                                        sx={{ background: "black", zIndex: 50, minWidth: "300px" }}
                                                    >
                                                        {talents
                                                            .sort((a, b) => a.Name.localeCompare(b.Name))
                                                            .map((talent) => (
                                                                <MenuItem key={talent.Key} value={talent.Key}>
                                                                    {talent.Name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </Grid>
                                                {index < editedItem.TalentRows.length - 1 && (
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={row.Directions[i].Down && editedItem.TalentRows[index + 1].Directions[i].Up}
                                                            onChange={() => {
                                                                let newRows = [...editedItem.TalentRows];
                                                                newRows[index].Directions[i].Down = !newRows[index].Directions[i].Down;
                                                                newRows[index + 1].Directions[i].Up = !newRows[index + 1].Directions[i].Up;
                                                                handleEdit({ ...editedItem, TalentRows: newRows });
                                                            }}
                                                            icon={<UpDownIcon />}
                                                            checkedIcon={<UpDownIcon checked />}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    ))}

                </>
            )}
            <br />
            <Typography>Is Universal:</Typography>
            <Checkbox
                checked={editedItem.Universal !== undefined && editedItem.Universal !== false}
                onChange={(e) => {
                    handleEdit({ ...editedItem, Universal: e.target.checked })
                }}
            />
            {editedItem.Universal && <>
                <Typography>Grants Force Rating:</Typography>
                <Checkbox
                    checked={editedItem.Attributes?.ForceRating}
                    onChange={(e) => {
                        let newAttributes = { ...editedItem.Attributes }
                        if (e.target.checked) {
                            newAttributes.ForceRating = 1
                        } else {
                            delete newAttributes.ForceRating
                        }
                        handleEdit({ ...editedItem, Attributes: newAttributes })
                    }}
                />
            </>}

        </Paper>
    )
}

export default SpecEdit