import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Button, TextField, Paper, Grid, Autocomplete } from '@mui/material';
import ItemEdit from '../FileEditComponents/ItemEdit';
import AttachmentEdit from '../FileEditComponents/AttachmentEdit';
import CareerEdit from '../FileEditComponents/CareerEdit'
import SpecEdit from '../FileEditComponents/SpecEdit';
import PowerEdit from '../FileEditComponents/PowerEdit';
import AbilityEdit from '../FileEditComponents/AbilityEdit'
import SpeciesEdit from '../FileEditComponents/SpeciesEdit';
import VehicleEdit from '../FileEditComponents/VehicleEdit';
import MentorEdit from '../FileEditComponents/MentorEdit';

function camelCaseDisplay(str) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, match => match.toUpperCase());
}


const DataEditModal = ({ open, handleClose, dataMap, appFiles, setAppFiles }) => {
    const [selectedDatafile, setSelectedDatafile] = useState('');
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedSubItem, setSelectedSubItem] = useState('')
    const [keyModalOpen, setKeyModalOpen] = useState(false);
    const [newKey, setNewKey] = useState('')
    const [cloning, setCloning] = useState(false)
    const inputRef = useRef(null);

    useEffect(() => {
        let newNewKey = cloning ? JSON.parse(dataMap[selectedDatafile])[selectedItem].Key + "COPY" : "USERENTRY" + Date.now()
        if (newKey !== newNewKey) {
            setNewKey(newNewKey)
        }
    }, [selectedItem, cloning]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [newKey]);

    const KeyModal = () => {
        return (<>
            <Box
                onClick={() => {
                    setKeyModalOpen(false)
                    setCloning(false)
                }}
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                }}
            />
            <Paper
                sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1100,
                    padding: '20px',
                    width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                }}>
                <Typography>Create a Key for the new item:</Typography>
                <TextField
                    value={newKey}
                    onChange={(e) => {
                        setNewKey(e.target.value.toUpperCase())
                        inputRef.current.focus()
                    }}
                    inputRef={inputRef}
                />
                <Button
                    sx={{ paddingTop: "5px" }}
                    disabled={!newKey || JSON.parse(dataMap[selectedDatafile]).find((i) => i.Key === newKey)}
                    onClick={() => {
                        let newAppFiles = { ...dataMap };
                        let newCat = [...JSON.parse(dataMap[selectedDatafile])]
                        let newItem = cloning ? { ...newCat[selectedItem] } : {}
                        newItem.Key = newKey
                        newItem.Name = newItem.Name + " COPY"
                        newCat.push(newItem)
                        newAppFiles[selectedDatafile] = JSON.stringify(newCat);
                        setAppFiles(newAppFiles);
                        localStorage.setItem('appFiles', JSON.stringify(newAppFiles));
                        setSelectedItem(newCat.length - 1)
                        setCloning(false)
                        setKeyModalOpen(false)
                    }}
                >Create Item</Button>
                {JSON.parse(dataMap[selectedDatafile]).find((i) => i.Key === newKey) && < Typography color="error">This key is already in use</Typography>}
            </Paper >
        </>)
    }

    const updateName = (str) => {
        let newAppFiles = { ...appFiles };
        let newFile = JSON.parse(dataMap[selectedDatafile])
        let updatedItem = { ...newFile[selectedItem] };
        updatedItem.Name = str;
        newFile[selectedItem] = updatedItem;
        newAppFiles[selectedDatafile] = JSON.stringify(newFile);
        setAppFiles(newAppFiles);
        localStorage.setItem('appFiles', JSON.stringify(newAppFiles));
    }

    const updateDesc = (str) => {
        let newAppFiles = { ...appFiles };
        let newFile = JSON.parse(dataMap[selectedDatafile])
        let updatedItem = { ...newFile[selectedItem] };
        updatedItem.Description = str;
        newFile[selectedItem] = updatedItem;
        newAppFiles[selectedDatafile] = JSON.stringify(newFile);
        setAppFiles(newAppFiles);
        localStorage.setItem('appFiles', JSON.stringify(newAppFiles));
    };

    return (
        <Modal open={open} onClose={handleClose} sx={{ padding: "40px", overflow: "auto" }}>
            <Paper sx={{ padding: "15px" }}>
                <Typography>Select file to edit:</Typography>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                    <Grid item xs="auto">
                        <Autocomplete
                            value={selectedDatafile}
                            onChange={(event, newValue) => {
                                setSelectedDatafile(newValue);
                                setSelectedItem('');
                            }}
                            options={Object.keys(dataMap).sort((a, b) => a.localeCompare(b))}
                            getOptionLabel={(option) => camelCaseDisplay(option)}
                            renderInput={(params) => <TextField {...params} label="Select File" />}
                            sx={{ minWidth: "300px" }}
                        />
                    </Grid>
                    {selectedDatafile && dataMap[selectedDatafile] && Array.isArray(JSON.parse(dataMap[selectedDatafile])) && (
                        <Grid item xs="auto">
                            <Autocomplete
                                value={selectedItem !== '' ? selectedItem : null}
                                onChange={(event, newValue) => setSelectedItem(newValue === 0 ? 0 : newValue || '')}
                                options={JSON.parse(dataMap[selectedDatafile])
                                    .map((item, index) => ({ item, index }))
                                    .sort((a, b) => (a.item.Power ? a.item.Power + a.item.Name : a.item.Name).localeCompare(b.item.Power ? b.item.Power + b.item.Name : b.item.Name))
                                    .map(sortedItem => sortedItem.index)
                                }
                                getOptionLabel={(option) => {
                                    const parsedData = JSON.parse(dataMap[selectedDatafile]);
                                    return (parsedData[option]?.Power ? parsedData[option]?.Power + " " : "" || "") + parsedData[option]?.Name || '';
                                }}
                                renderInput={(params) => <TextField {...params} label="Select Item" />}
                                sx={{ minWidth: "300px" }}
                                isOptionEqualToValue={(option, value) => option === value || value === null}
                            />
                        </Grid>
                    )}
                    {selectedDatafile && dataMap[selectedDatafile] && JSON.parse(dataMap[selectedDatafile]).MentorHistory && (
                        <Grid item xs="auto">
                            <Autocomplete
                                value={selectedSubItem !== '' ? selectedSubItem : null}
                                onChange={(event, newValue) => setSelectedSubItem(newValue || '')}
                                options={["Mentor History", "Mentor Personality", "Mentor Complications", "Mentor Force Traditions"]}
                                renderInput={(params) => <TextField {...params} label="Mentor Details" />}
                                sx={{ minWidth: "300px" }}
                                isOptionEqualToValue={(option, value) => option === value || value === null}
                            />
                        </Grid>
                    )}
                    {(selectedItem !== '') && <Grid item xs="auto">
                        <Button onClick={() => {
                            setCloning(true)
                            setKeyModalOpen(true)
                        }}>Clone Selected</Button>
                    </Grid>}
                    {(selectedDatafile !== '' && selectedDatafile !== 'mentors') && <Grid item xs="auto">
                        <Button onClick={() => setKeyModalOpen(true)}>New Item</Button>
                    </Grid>}

                </Grid>

                {(selectedItem !== '') && (
                    <>
                        <Typography>Key: {JSON.parse(dataMap[selectedDatafile])[selectedItem].Key}</Typography>
                        <Typography>Name:</Typography>
                        <TextField
                            value={JSON.parse(dataMap[selectedDatafile])[selectedItem].Name}
                            onChange={(e) => updateName(e.target.value)}
                        />
                        <Typography>Description:</Typography>
                        <TextField
                            fullWidth
                            multiline
                            value={JSON.parse(dataMap[selectedDatafile])[selectedItem].Description}
                            onChange={(e) => updateDesc(e.target.value)}
                        />
                        {['armor', 'weapons', 'gear'].includes(selectedDatafile) && <ItemEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} category={selectedDatafile} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'attachments' && <AttachmentEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'careers' && <CareerEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'specializations' && <SpecEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'forcePowers' && <PowerEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'forceAbilities' && <AbilityEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'species' && <SpeciesEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        {selectedDatafile === 'vehicles' && <VehicleEdit editedItem={JSON.parse(dataMap[selectedDatafile])[selectedItem]} index={selectedItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                        <br />
                        <Button onClick={() => {
                            if (window.confirm("Are you sure you want to delete " + JSON.parse(dataMap[selectedDatafile])[selectedItem].Name + "?")) {
                                let newAppFiles = { ...appFiles };
                                let newFile = JSON.parse(dataMap[selectedDatafile]);
                                newFile.splice(selectedItem, 1);
                                setSelectedItem('');
                                newAppFiles[selectedDatafile] = JSON.stringify(newFile);
                                setAppFiles(newAppFiles);
                                localStorage.setItem('appFiles', JSON.stringify(newAppFiles));
                            }
                        }}>Delete Selected Item</Button>

                    </>
                )}
                {selectedDatafile === 'mentors' && selectedSubItem && <MentorEdit editedItem={JSON.parse(dataMap[selectedDatafile])} index={selectedSubItem} appFiles={dataMap} setAppFiles={setAppFiles} />}
                <Box>
                    <Button onClick={handleClose}>Close</Button>
                </Box>
                {keyModalOpen && <KeyModal />}
            </Paper>
        </Modal>
    );
};

export default DataEditModal;
