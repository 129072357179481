import { Paper, Typography, TextField, Grid, IconButton, Button, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SvgIcon } from '@mui/material';
import skills from "../data/skills.json"

const LeftRightIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 100 20"
        sx={{ width: "100px", height: "20px" }} >
        <rect width="100" height="20" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);

const UpDownIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 20 50"
        sx={{ width: "20px", height: "50px" }}
    >
        <rect width="20" height="50" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);


const PowerEdit = ({ editedItem, index, appFiles, setAppFiles }) => {

    const powers = JSON.parse(appFiles.forcePowers)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["forceAbilities"]);
        newCat[index] = newItem;
        newFiles["forceAbilities"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    if (editedItem.DieModifiers?.DieModifier && !Array.isArray(editedItem.DieModifiers)) {
        handleEdit({ ...editedItem, DieModifiers: [editedItem.DieModifiers] })
    }

    return (
        <Paper>
            <Typography>Power:</Typography>
            <Select
                value={editedItem.Power}
                onChange={(e) => {
                    handleEdit({ ...editedItem, Power: e.target.value });
                }}
            >
                {powers
                    .sort((a, b) => a.Name.localeCompare(b.Name))
                    .map((power) => (
                        <MenuItem key={power.Key} value={power.Name}>
                            {power.Name}
                        </MenuItem>
                    ))}
            </Select>
            <Typography>Add Force to skills:</Typography>
            {Array.isArray(editedItem.DieModifiers) &&
                editedItem.DieModifiers.map((mod, modIndex) => (
                    <>
                        <Select
                            sx={{ minWidth: "100px" }}
                            key={modIndex}
                            value={mod.SkillKey}
                            onChange={(e) => {
                                let newDiceMods = [...editedItem.DieModifiers];
                                newDiceMods[modIndex] = { SkillKey: e.target.value, ForceCount: 1 };
                                handleEdit({ ...editedItem, DieModifiers: newDiceMods });
                            }}
                        >
                            {skills
                                .filter((s) => {
                                    const selectedSkillKey = editedItem.DieModifiers[modIndex]?.SkillKey;
                                    return !editedItem.DieModifiers.map((mod) => mod.SkillKey).includes(s.Key) || s.Key === selectedSkillKey;
                                })
                                .sort((a, b) => a.skill.localeCompare(b.skill))
                                .map((skill) => (
                                    <MenuItem key={skill.Key} value={skill.Key}>
                                        {skill.skill}
                                    </MenuItem>
                                ))}
                        </Select>
                        <IconButton onClick={(e) => {
                            let newDiceMods = [...editedItem.DieModifiers];
                            newDiceMods.splice(modIndex, 1)
                            handleEdit({ ...editedItem, DieModifiers: newDiceMods });
                        }}
                            sx={{ maxWidth: "30px" }}
                            size="small"
                            className="no-print"
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                ))}
            <Button onClick={(e) => {
                let newDiceMods = [...editedItem.DieModifiers || []];
                newDiceMods.push({})
                handleEdit({ ...editedItem, DieModifiers: newDiceMods });
            }}
                className="no-print"
            >
                Add
            </Button>
        </Paper>
    )
}

export default PowerEdit