import { Paper, Typography, TextField, Grid, Button, Select, Checkbox, MenuItem } from "@mui/material";
import { SvgIcon } from '@mui/material';

const LeftRightIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 100 20"
        sx={{ width: "100px", height: "20px" }} >
        <rect width="100" height="20" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);

const UpDownIcon = ({ checked }) => (
    <SvgIcon
        viewBox="0 0 20 50"
        sx={{ width: "20px", height: "50px" }}
    >
        <rect width="20" height="50" fill={checked ? "green" : "gray"} />
    </SvgIcon>
);


const PowerEdit = ({ editedItem, index, appFiles, setAppFiles }) => {

    const abilities = JSON.parse(appFiles.forceAbilities).filter((a) => a.Power === editedItem.Name)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["forcePowers"]);
        newCat[index] = newItem;
        newFiles["forcePowers"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    return (
        <Paper>
            <Typography>Ability Grid:</Typography>
            {editedItem.AbilityRows.map((row, index) => (
                <>
                    <Grid container key={index} spacing={2}>
                        {row.Abilities.map((ability, i) => (
                            <Grid item xs={parseInt(row.AbilitySpan[i]) * 3} key={i}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={4 / parseInt(row.AbilitySpan[i])}
                                        sx={{
                                            position: "relative",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        {i > 0 && (
                                            <Grid container direction="column" alignItems="center" fullWidth>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            let newRows = [...editedItem.AbilityRows];
                                                            let newRow = { ...row };
                                                            if (i > 0 && i < newRow.Abilities.length) {
                                                                newRow.AbilitySpan[i - 1] = parseInt(newRow.AbilitySpan[i - 1]) + parseInt(newRow.AbilitySpan[i]);
                                                                newRow.Abilities.splice(i, 1);
                                                                newRow.AbilitySpan.splice(i, 1);
                                                                newRows[index] = newRow;
                                                                handleEdit({ ...editedItem, AbilityRows: newRows });
                                                            }
                                                        }}
                                                    >
                                                        {"⟹ ⟸"}
                                                    </Button>

                                                </Grid>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={row.Directions[i].Left && row.Directions[i - 1].Right}
                                                        onChange={() => {
                                                            let newRows = [...editedItem.AbilityRows];
                                                            newRows[index].Directions[i].Left = !newRows[index].Directions[i].Left;
                                                            newRows[index].Directions[i - 1].Right = !newRows[index].Directions[i - 1].Right;
                                                            handleEdit({ ...editedItem, AbilityRows: newRows });
                                                        }}
                                                        icon={<LeftRightIcon />}
                                                        checkedIcon={<LeftRightIcon checked />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={8 + (4 - (4 / parseInt(row.AbilitySpan[i])))}>
                                        <Grid container direction="column" alignItems="center" fullWidth>
                                            <Grid item>
                                                <Button
                                                    disabled={!(row.AbilitySpan[i] > 1)}
                                                    onClick={() => {
                                                        let newRows = [...editedItem.AbilityRows];
                                                        let newRow = { ...row };
                                                        const currentSpan = parseInt(newRow.AbilitySpan[i]);
                                                        if (currentSpan > 1) {
                                                            newRow.AbilitySpan[i] = 1;
                                                            newRow.Abilities.splice(i + 1, 0, "");
                                                            newRow.AbilitySpan.splice(i + 1, 0, currentSpan - 1);
                                                            newRows[index] = newRow;
                                                            handleEdit({ ...editedItem, AbilityRows: newRows });
                                                        }
                                                    }}
                                                >
                                                    {"⟺"}
                                                </Button>

                                            </Grid>
                                            <Grid item sx={{ width: "100%" }}>
                                                <Grid container alignItems="center">
                                                    <Grid item sx={{ flexGrow: 1 }}>
                                                        <Select
                                                            value={ability}
                                                            onChange={(e) => {
                                                                let newRows = [...editedItem.AbilityRows];
                                                                let newRow = { ...row };
                                                                let newAbilities = newRow.Abilities;
                                                                newAbilities[i] = e.target.value;
                                                                newRow.Abilities = newAbilities;
                                                                newRows[index] = newRow;
                                                                handleEdit({ ...editedItem, AbilityRows: newRows });
                                                            }}
                                                            sx={{ background: "black", zIndex: 50, width: "100%" }}
                                                        >
                                                            {abilities
                                                                .sort((a, b) => a.Name.localeCompare(b.Name))
                                                                .map((ability) => (
                                                                    <MenuItem key={ability.Key} value={ability.Key}>
                                                                        {ability.Name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </Grid>
                                                    <Grid item sx={{ width: 'auto', paddingLeft: 1 }}>
                                                        <TextField
                                                            value={row.Costs[i]}
                                                            label="Cost"
                                                            sx={{ maxWidth: "60px" }}
                                                            onChange={(e) => {
                                                                let newRows = [...editedItem.AbilityRows];
                                                                let newRow = { ...row };
                                                                let newCosts = [...newRow.Costs];
                                                                newCosts[i] = e.target.value;
                                                                newRow.Costs = newCosts;
                                                                newRows[index] = newRow;
                                                                handleEdit({ ...editedItem, AbilityRows: newRows });
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {index < (editedItem.AbilityRows.length - 1) && <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Grid container justifyContent="center" alignItems="center">
                                {Array.from({ length: 4 }).map((_, i) => (
                                    <Grid item key={i} xs={3}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Checkbox
                                                checked={row.Directions[i].Down && editedItem.AbilityRows[index + 1].Directions[i].Up}
                                                onChange={() => {
                                                    let newRows = [...editedItem.AbilityRows];
                                                    newRows[index].Directions[i].Down = !newRows[index].Directions[i].Down;
                                                    newRows[index + 1].Directions[i].Up = !newRows[index + 1].Directions[i].Up;
                                                    handleEdit({ ...editedItem, AbilityRows: newRows });
                                                }}
                                                icon={<UpDownIcon />}
                                                checkedIcon={<UpDownIcon checked />}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>}
                </>
            ))}
        </Paper>
    )
}

export default PowerEdit