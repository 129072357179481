import { Paper, Typography, TextField, Grid, IconButton, Button, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import skills from '../data/skills.json'
import characteristics from '../data/characteristics.json'

const AttachmentEdit = ({ editedItem, index, appFiles, setAppFiles }) => {
    let category = editedItem.Type
    const qualities = JSON.parse(appFiles.qualities)
    const talents = JSON.parse(appFiles.talents)
    const filteredQualities = qualities.filter(q => !q.Type || (q.Type === category))

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles[category]);
        newCat[index] = newItem;
        newFiles[category] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    if (editedItem.BaseMods && !Array.isArray(editedItem.BaseMods)) { editedItem.BaseMods = [editedItem.BaseMods] }
    if (editedItem.AddedMods && !Array.isArray(editedItem.AddedMods)) { editedItem.AddedMods = [editedItem.AddedMods] }


    return (
        <Paper>
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Price"
                value={editedItem?.Price || 0}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Price: e.target.value })
                }
            />
            <TextField
                sx={{
                    marginTop: "10px",
                    width: "50%",
                }}
                label="Rarity"
                value={editedItem?.Rarity || ""}
                onChange={(e) =>
                    handleEdit({ ...editedItem, Rarity: e.target.value })
                }
            />

            <Typography>Base Mods:</Typography>
            {editedItem.BaseMods && (
                <Grid container>
                    {editedItem.BaseMods.map((mod, index) => (
                        <Grid item key={index} sx={{ margin: "7.5px" }}>
                            {(mod.MiscDesc || mod.MiscDesc === "") &&
                                <>
                                    <Typography>Custom Mod:</Typography>
                                    <TextField
                                        label="Description"
                                        sx={{ minWidth: "500px" }}
                                        value={mod.MiscDesc}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].MiscDesc = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && skills.find(s => s.Key === mod.Key) &&
                                <>
                                    <Typography>Skill:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                            <MenuItem key={skill.Key} value={skill.Key}>
                                                {skill.skill}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                    {" "} Make Career:
                                    <Checkbox
                                        checked={!!mod.SkillIsCareer}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].SkillIsCareer = e.target.checked;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && talents.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Innate Talent:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {talents.sort((a, b) => a.Name.localeCompare(b.Name)).map((talent) => (
                                            <MenuItem key={talent.Key} value={talent.Key}>
                                                {talent.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && characteristics.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Characteristic Mod:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "180px" }}
                                    >
                                        {characteristics.map((characteristic) => (
                                            <MenuItem key={characteristic.Key} value={characteristic.Key}>
                                                {characteristic.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && qualities.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Quality:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                    >
                                        {filteredQualities.sort((a, b) => a.Name.localeCompare(b.Name)).map((quality) => (
                                            <MenuItem key={quality.Key} value={quality.Key}>
                                                {quality.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.BaseMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, BaseMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            <IconButton onClick={(e) => {
                                let newMods = [...editedItem.BaseMods];
                                newMods.splice(index, 1)
                                handleEdit({ ...editedItem, BaseMods: newMods });
                            }}
                                sx={{ maxWidth: "30px" }}
                                size="small"
                                className="no-print"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods || []];
                newMods.push({ MiscDesc: "" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Custom Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "BR" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Characteristic Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "ASTRO" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Skill Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: "ADV" })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Talent Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.BaseMods];
                newMods.push({ Key: filteredQualities[0].Key })
                handleEdit({ ...editedItem, BaseMods: newMods });
            }}
                className="no-print"
            >
                Add Quality
            </Button>


            <Typography>Added Mods:</Typography>
            {editedItem.AddedMods && (
                <Grid container>
                    {editedItem.AddedMods.map((mod, index) => (
                        <Grid item key={index} sx={{ margin: "7.5px" }}>
                            {(mod.MiscDesc || mod.MiscDesc === "") &&
                                <>
                                    <Typography>Custom Mod:</Typography>
                                    <TextField
                                        label="Description"
                                        sx={{ minWidth: "500px" }}
                                        value={mod.MiscDesc}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].MiscDesc = e.target.value;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && skills.find(s => s.Key === mod.Key) &&
                                <>
                                    <Typography>Skill:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                    >
                                        {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                            <MenuItem key={skill.Key} value={skill.Key}>
                                                {skill.skill}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                    {" "} Make Career:
                                    <Checkbox
                                        checked={!!mod.SkillIsCareer}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].SkillIsCareer = e.target.checked;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                    />
                                </>
                            }
                            {mod.Key && talents.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Innate Talent:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                    >
                                        {talents.sort((a, b) => a.Name.localeCompare(b.Name)).map((talent) => (
                                            <MenuItem key={talent.Key} value={talent.Key}>
                                                {talent.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && characteristics.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Characteristic Mod:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                        sx={{ maxWidth: "180px" }}
                                    >
                                        {characteristics.map((characteristic) => (
                                            <MenuItem key={characteristic.Key} value={characteristic.Key}>
                                                {characteristic.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            {mod.Key && qualities.find(t => t.Key === mod.Key) &&
                                <>
                                    <Typography>Quality:</Typography>
                                    <Select
                                        value={mod.Key || ''}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Key = e.target.value;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                    >
                                        {filteredQualities.sort((a, b) => a.Name.localeCompare(b.Name)).map((quality) => (
                                            <MenuItem key={quality.Key} value={quality.Key}>
                                                {quality.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        label="Ranks"
                                        value={mod.Count || 0}
                                        onChange={(e) => {
                                            let newMods = [...editedItem.AddedMods];
                                            newMods[index].Count = !isNaN(e.target.value) ? e.target.value : newMods[index].Count || 0;
                                            handleEdit({ ...editedItem, AddedMods: newMods });
                                        }}
                                        sx={{ maxWidth: "90px" }}
                                    />
                                </>
                            }
                            <IconButton onClick={(e) => {
                                let newMods = [...editedItem.AddedMods];
                                newMods.splice(index, 1)
                                handleEdit({ ...editedItem, AddedMods: newMods });
                            }}
                                sx={{ maxWidth: "30px" }}
                                size="small"
                                className="no-print"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Button onClick={(e) => {
                let newMods = [...editedItem.AddedMods || []];
                newMods.push({ MiscDesc: "" })
                handleEdit({ ...editedItem, AddedMods: newMods });
            }}
                className="no-print"
            >
                Add Custom Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.AddedMods];
                newMods.push({ Key: "BR" })
                handleEdit({ ...editedItem, AddedMods: newMods });
            }}
                className="no-print"
            >
                Add Characteristic Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.AddedMods];
                newMods.push({ Key: "ASTRO" })
                handleEdit({ ...editedItem, AddedMods: newMods });
            }}
                className="no-print"
            >
                Add Skill Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.AddedMods];
                newMods.push({ Key: "ADV" })
                handleEdit({ ...editedItem, AddedMods: newMods });
            }}
                className="no-print"
            >
                Add Talent Mod
            </Button>
            <Button onClick={(e) => {
                let newMods = [...editedItem.AddedMods];
                newMods.push({ Key: filteredQualities[0].Key })
                handleEdit({ ...editedItem, AddedMods: newMods });
            }}
                className="no-print"
            >
                Add Quality
            </Button>

        </Paper>
    )
}

export default AttachmentEdit