import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Select,
  MenuItem,
  Typography,
  Button,
  Grid,
  Paper,
  ListItemText,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import DiceModifiers from "./Util/DiceModifiers";
import FreeRanks from "./Util/FreeRanks";
import getDicePool from "./Util/GetDicePool";
import getFullBoughtTalents from "./Util/GetFullBoughtTalents";
import characteristicsData from "./data/characteristics.json"
import talentsData from "./data/talents.json"
import forcePowersData from "./data/forcePowers.json";
import forceAbilitiesData from "./data/forceAbilities.json";
import skillsData from "./data/skills.json";

let forcePowers = forcePowersData
let forceAbilities = forceAbilitiesData
let skills = skillsData
let talents = talentsData

function App({ character, handleCharacterChange, appFiles }) {
  const [characteristics, setCharacteristics] = useState({
    Brawn: parseInt(character.Characteristics.Brawn) + (character.Dedications?.Brawn?.length || 0),
    Agility: parseInt(character.Characteristics.Agility) + (character.Dedications?.Agility?.length || 0),
    Intellect: parseInt(character.Characteristics.Intellect) + (character.Dedications?.Intellect?.length || 0),
    Cunning: parseInt(character.Characteristics.Cunning) + (character.Dedications?.Cunning?.length || 0),
    Willpower: parseInt(character.Characteristics.Willpower) + (character.Dedications?.Willpower?.length || 0),
    Presence: parseInt(character.Characteristics.Presence) + (character.Dedications?.Presence?.length || 0),
  });
  const [usedStartingXP, setUsedStartingXP] = useState(character.UsedStartingXP || 0)
  const [leftSkills, setLeftSkills] = useState([])
  const [rightSkills, setRightSkills] = useState([])
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (appFiles.skills) { skills = JSON.parse(appFiles.skills) }
    if (appFiles.forcePowers) { forcePowers = JSON.parse(appFiles.forcePowers) }
    if (appFiles.forceAbilities) { forceAbilities = JSON.parse(appFiles.forceAbilities) }
    if (appFiles.talents) { talents = JSON.parse(appFiles.talents) }
  }, [appFiles.skills, appFiles.forceAbilities, appFiles.forcePowers, appFiles.talents]);

  const dieModifiers = DiceModifiers(character, handleCharacterChange, talents, forcePowers, forceAbilities, skills)
  const freeRanks = FreeRanks(character);

  const { Bought: boughtTalents, universalForce } = getFullBoughtTalents(character);

  let charMods = {}
  for (let t in boughtTalents) {
    let tal = boughtTalents[t].data
    if (tal.SkillChars) {
      if (tal.SkillChars.SkillChar.CharKeys) { charMods[tal.SkillChars.SkillChar.SkillKey] = tal.SkillChars.SkillChar.CharKeys }
      else {
        charMods[tal.SkillChars.SkillChar.SkillKey] = tal.SkillChars.SkillChar.CharKey
      }
    }
  }

  const startingXP = () => {
    return (parseInt(character.Species.StartingAttrs?.Experience) || 0) +
      (character.Obligations[0].XP10 ? 10 : 0) +
      (character.Obligations[0].XP5 ? 5 : 0) +
      (character.Duties[0].XP10 ? 10 : 0) +
      (character.Duties[0].XP5 ? 5 : 0) +
      (character.Morality.XP10 ? 10 : 0) +
      (character.Morality.XPC ? 5 : 0)
  }

  const handleCharacteristicChange = (skillKey, newCharacteristic) => {
    let newSkills = [...character.Skills]
    newSkills.find((s) => s.Key === skillKey).ModChar = newCharacteristic
    handleCharacterChange({ Skills: newSkills })
  };

  const getTotalRanks = (skill) => {
    let totalRanks = skill.value || 0;
    if (freeRanks[skill.Key]) {
      totalRanks += freeRanks[skill.Key];
    }
    return totalRanks;
  };

  const handleChangeCharacteristics = (characteristic, value, xpChange) => {
    let speciesMin = parseInt(character.Species.StartingChars[characteristic])
    value = Math.min(5, Math.max(speciesMin, value));
    setUsedStartingXP(usedStartingXP - parseInt(xpChange))
    setCharacteristics((prevCharacteristics) => ({
      ...prevCharacteristics,
      [characteristic]: value,
    }));
    handleCharacterChange({
      Characteristics: { ...characteristics, [characteristic]: value },
      XP: parseInt(character.XP) + parseInt(xpChange),
      UsedStartingXP: usedStartingXP - parseInt(xpChange)
    });
  };

  function isCareerSkill(skillName) {
    if (
      character.CareerSkills.includes(skillName) ||
      character.SpecSkills.includes(skillName) ||
      character.ExtraCareerSkills.includes(skillName) ||
      character.SelectedCareerSkills[skillName]
    ) {
      return true;
    } else return false;
  }

  const handleIncrement = (characteristic) => {
    handleChangeCharacteristics(
      characteristic,
      parseInt(characteristics[characteristic]) + 1,
      -(parseInt(characteristics[characteristic]) + 1) * 10,
    );
  };

  const handleDecrement = (characteristic) => {
    handleChangeCharacteristics(
      characteristic,
      characteristics[characteristic] - 1,
      parseInt(characteristics[characteristic]) * 10,
    );
  };

  const decreaseSkillValue = (skill) => {
    if (getTotalRanks(skill) > (freeRanks[skill.Key] || 0)) {
      var outOfCareerCost = 0;
      if (!isCareerSkill(skill.skill)) {
        outOfCareerCost = 5;
      }
      skill.value -= 1;
      handleCharacterChange({
        XP: character.XP + (getTotalRanks(skill) + 1) * 5 + outOfCareerCost,
      });
    }
  };

  const increaseSkillValue = (skill) => {
    if (skill.value === undefined) {
      skill.value = 0;
    }
    let freeRank = 0;
    if (freeRanks[skill.Key]) {
      freeRank += freeRanks[skill.Key];
    }
    if (skill.value + freeRank < 5) {
      var outOfCareerCost = 0;
      if (!isCareerSkill(skill.skill)) {
        outOfCareerCost = 5;
      }
      skill.value += 1;
      handleCharacterChange({
        XP: character.XP - (getTotalRanks(skill) * 5 + outOfCareerCost),
      });
    }
  };

  useEffect(() => {
    const skillsData = [...character.Skills];
    const groupedSkills = {};
    skillsData.forEach((skill) => {
      if (!groupedSkills[skill.type]) {
        groupedSkills[skill.type] = [];
      }
      groupedSkills[skill.type].push(skill);
    });

    Object.keys(groupedSkills).forEach((type) => {
      groupedSkills[type] = groupedSkills[type].sort((a, b) =>
        a.skill.localeCompare(b.skill),
      );
    });

    const sortedSkills = Object.values(groupedSkills).flat();

    const sortedGeneralSkills = sortedSkills.filter(
      (skill) => skill.type === "General",
    );
    const sortedNonGeneralSkills = sortedSkills.filter(
      (skill) => skill.type !== "General",
    );

    const allSkills = [...sortedGeneralSkills, ...sortedNonGeneralSkills];
    const splitLength = Math.ceil(allSkills.length / 2);
    setLeftSkills(allSkills.slice(0, splitLength))
    setRightSkills(allSkills.slice(splitLength))
  }, [character]);

  function SkillTable() {

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Skill</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Char</Typography>
                  </TableCell>
                  {!isSmallScreen && <TableCell>
                    <Typography>Type</Typography>
                  </TableCell>}
                  <TableCell>
                    <Typography align="center">Career</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Adjust Ranks</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Dice Pool</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leftSkills.map((skill, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <ListItemText primary={skill.skill} />
                    </TableCell>
                    <TableCell>
                      {charMods[skill.Key] ? (
                        <Select
                          value={skill.ModChar ? skill.ModChar : skill.characteristic}
                          onChange={(event) => handleCharacteristicChange(skill.Key, event.target.value)}
                          size="small"
                        >
                          <MenuItem value={skill.characteristic}>{skill.characteristic}</MenuItem>
                          {!Array.isArray(charMods[skill.Key]) && (<MenuItem value={characteristicsData.find((c) => c.Key === charMods[skill.Key]).Name}>{characteristicsData.find((c) => c.Key === charMods[skill.Key]).Name}</MenuItem>)}
                          {Array.isArray(charMods[skill.Key]) && charMods[skill.Key].map((char) => (
                            <MenuItem value={characteristicsData.find((c) => c.Key === char).Name}>{characteristicsData.find((c) => c.Key === char).Name}</MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <ListItemText secondary={skill.characteristic} />
                      )}
                    </TableCell>
                    {!isSmallScreen && <TableCell>
                      <ListItemText secondary={skill.type} />
                    </TableCell>}
                    <TableCell>
                      {isCareerSkill(skill.skill) && (
                        <Typography align="center">✔</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "100px",
                      }}
                    >
                      <div align="center">
                        <Button
                          onClick={() => decreaseSkillValue(skill)}
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          size="small"
                        >
                          -
                        </Button>
                        {getTotalRanks(skill) || 0}
                        <Button
                          onClick={() => increaseSkillValue(skill)}
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          size="small"
                        >
                          +
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell>
                      {getDicePool(
                        character,
                        characteristics[skill.ModChar ? skill.ModChar : skill.characteristic],
                        getTotalRanks(skill) || 0,
                        skill,
                        dieModifiers
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Skill</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Char</Typography>
                  </TableCell>
                  {!isSmallScreen && <TableCell>
                    <Typography>Type</Typography>
                  </TableCell>}
                  <TableCell>
                    <Typography align="center">Career</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">Adjust Ranks</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Dice Pool</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rightSkills.map((skill, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <ListItemText primary={skill.skill} />
                    </TableCell>
                    <TableCell>
                      {charMods[skill.Key] ? (
                        <Select
                          value={skill.ModChar ? skill.ModChar : skill.characteristic}
                          onChange={(event) => handleCharacteristicChange(skill.Key, event.target.value)}
                          size="small"
                        >
                          <MenuItem value={skill.characteristic}>{skill.characteristic}</MenuItem>
                          {!Array.isArray(charMods[skill.Key]) && (<MenuItem value={characteristicsData.find((c) => c.Key === charMods[skill.Key]).Name}>{characteristicsData.find((c) => c.Key === charMods[skill.Key]).Name}</MenuItem>)}
                          {Array.isArray(charMods[skill.Key]) && charMods[skill.Key].map((char) => (
                            <MenuItem value={characteristicsData.find((c) => c.Key === char).Name}>{characteristicsData.find((c) => c.Key === char).Name}</MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <ListItemText secondary={skill.characteristic} />
                      )}
                    </TableCell>
                    {!isSmallScreen && <TableCell>
                      <ListItemText secondary={skill.type} />
                    </TableCell>}
                    <TableCell>
                      {isCareerSkill(skill.skill) && (
                        <Typography align="center">✔</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "100px",
                      }}
                    >
                      <div align="center">
                        <Button
                          onClick={() => decreaseSkillValue(skill)}
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          size="small"
                        >
                          -
                        </Button>
                        {getTotalRanks(skill) || 0}
                        <Button
                          onClick={() => increaseSkillValue(skill)}
                          style={{
                            fontSize: "0.7rem",
                            minWidth: "30px",
                          }}
                          size="small"
                        >
                          +
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell>
                      {getDicePool(
                        character,
                        characteristics[skill.ModChar ? skill.ModChar : skill.characteristic],
                        getTotalRanks(skill) || 0,
                        skill,
                        dieModifiers
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h3" style={{ marginLeft: "30px" }}>
        Characteristics
      </Typography>
      {usedStartingXP > startingXP() && <Typography color="red" sx={{ paddingLeft: '30px' }}>Starting XP limit exceeded by {usedStartingXP - startingXP()}</Typography>}
      {character.Species.StartingChars && (
        <Paper>
          <Grid container spacing={2}>
            {Object.entries(characteristics).map(([key, value]) => (
              <Grid
                item
                xs={6}
                sm={4}
                lg={2}
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" align="center">
                  {key}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleDecrement(key)}
                    disabled={
                      parseInt(value) ===
                      parseInt(character.Species.StartingChars[key]) + (character.Dedications ? (character.Dedications[key]?.length || 0) : 0)
                    }
                  >
                    -
                  </Button>
                  <span style={{ margin: "0 8px" }}>{value}</span>
                  <Button
                    variant="contained"
                    onClick={() => handleIncrement(key)}
                    disabled={value === 5}
                  >
                    +
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
      {!character.Species.StartingChars && (
        <Typography style={{ marginLeft: "30px" }}>
          Select a species to set starting characteristics.
        </Typography>
      )}
      <Typography variant="h3" style={{ marginLeft: "30px" }}>
        Skills
      </Typography>
      <SkillTable />
    </>
  );
}

export default App;
