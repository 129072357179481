import React, { useState, useEffect } from "react";
import { Container, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, CardMedia, Grid, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import JSZip from "jszip";
import DataEditModal from "./Util/DataEditModal"
import armor from "./data/armor.json"
import attachments from "./data/attachments.json"
import callToAdventure from "./data/callToAdventure.json"
import careers from "./data/careers.json"
import craftingResultTables from "./data/craftingResultTables.json"
import craftingTemplates from "./data/craftingTemplates.json"
import cultures from "./data/cultures.json"
import duties from "./data/duties.json"
import forceExperience from "./data/forceExperience.json"
import forceAbilities from "./data/forceAbilities.json"
import forcePowers from "./data/forcePowers.json"
import gear from "./data/gear.json"
import mentors from "./data/mentors.json"
import moralities from "./data/moralities.json"
import motivations from "./data/motivations.json"
import obligations from "./data/obligations.json"
import qualities from "./data/qualities.json"
import signatureAbilities from "./data/signatureAbilities.json"
import signatureAbilityNodes from "./data/signatureAbilityNodes.json"
import specializations from "./data/specializations.json"
import species from "./data/species.json"
import specificMotivations from "./data/specificMotivations.json"
import talents from "./data/talents.json"
import vehicles from "./data/vehicles.json"
import weapons from "./data/weapons.json"

const defaultDataMap = {
    armor,
    attachments,
    callToAdventure,
    careers,
    craftingResultTables,
    craftingTemplates,
    cultures,
    duties,
    forceAbilities,
    forceExperience,
    forcePowers,
    gear,
    mentors,
    moralities,
    motivations,
    obligations,
    qualities,
    signatureAbilities,
    signatureAbilityNodes,
    specializations,
    species,
    specificMotivations,
    talents,
    vehicles,
    weapons
};

const ReplaceDataModal = ({ appFiles, setAppFiles }) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [replaceMode, setReplaceMode] = useState('whole');

    useEffect(() => {
        const storedFiles = JSON.parse(localStorage.getItem('appFiles') || '{}');
        setAppFiles(storedFiles);
    }, []);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.name.toLowerCase().endsWith('.zip')) {
                setFile(selectedFile);
                setError('');
            } else {
                setFile(null);
                setError('Please select a file with .zip extension.');
            }
        } else {
            setFile(null);
            setError('No file selected.');
        }
    };

    async function unpackZipInMemory(file) {
        const zip = new JSZip();
        try {
            const arrayBuffer = await file.arrayBuffer();
            const loadedZip = await zip.loadAsync(arrayBuffer, { checkCRC32: true });
            return loadedZip;
        } catch (error) {
            throw new Error(`Failed to unpack ZIP: ${error.message}`);
        }
    }

    const fileExists = (fileName) => {
        const validFiles = [
            'armor.json', 'attachments.json', 'callToAdventure.json', 'careers.json', 'craftingTemplates.json',
            'craftingResultTables.json', 'characterSheet.json', 'cultures.json', 'duties.json', 'forceAbilities.json',
            'forceExperience.json', 'forcePowers.json', 'gear.json', 'mentors.json', 'moralities.json', 'motivations.json',
            'obligations.json', 'qualities.json', 'signatureAbilities.json', 'signatureAbilityNodes.json', 'skills.json',
            'specializations.json', 'species.json', 'specificMotivations.json', 'talents.json', 'vehicles.json',
            'warriorRewards.json', 'weapons.json'
        ];

        return validFiles.includes(fileName);
    };

    function updateFile(path, content, setAppFiles) {
        const minifiedContent = JSON.stringify(content);
        const files = JSON.parse(localStorage.getItem('appFiles') || '{}');
        files[path] = minifiedContent;
        localStorage.setItem('appFiles', JSON.stringify(files));

        setAppFiles(prevFiles => ({
            ...prevFiles,
            [path]: content
        }));
    }


    function mergeKeyValueUpdates(existingFileContent, newFileContent) {
        const existingData = JSON.parse(existingFileContent);
        const newData = JSON.parse(newFileContent);

        if (!Array.isArray(existingData) || !Array.isArray(newData)) {
            return JSON.stringify(existingData);
        }

        const newDataMap = new Map(
            newData.map(item => [(item.Key || item.Name), item.Description])
        );

        const updatedData = existingData.map(item => {
            const identifier = item.Key || item.Name;
            if (newDataMap.has(identifier)) {
                return { ...item, Description: newDataMap.get(identifier) };
            }
            return item;
        });

        newData.forEach(newItem => {
            const identifier = newItem.Key || newItem.Name;
            if (!existingData.some(item => (item.Key || item.Name) === identifier)) {
                updatedData.push(newItem);
            }
        });

        return JSON.stringify(updatedData, null, 2);
    }



    async function compareAndReplaceFiles(zip, setAppFiles) {
        const filesToReplace = [];

        for (const [relativePath, zipEntry] of Object.entries(zip.files)) {
            if (!zipEntry.dir) {
                const fileName = relativePath.split('/').pop();
                if (fileExists(fileName)) {
                    filesToReplace.push(fileName);
                }
            }
        }

        let summary = 'The following changes will be made:\n\n';
        summary += filesToReplace.length > 0 ? 'Files to be replaced:\n' + filesToReplace.join('\n') + '\n\n' : 'No files to replace.\n\n';

        if (!confirm(summary + 'Do you want to proceed with these changes?')) {
            return 'Operation cancelled by user.';
        }

        for (const [relativePath, zipEntry] of Object.entries(zip.files)) {
            if (!zipEntry.dir) {
                try {
                    const content = await zipEntry.async('string');
                    const fileName = relativePath.split('/').pop();
                    const fileKey = fileName.slice(0, -5);
                    const existingContent = appFiles[fileKey];

                    if (replaceMode === 'keyValue') {
                        if (existingContent) {
                            const mergedContent = mergeKeyValueUpdates(existingContent, content);
                            updateFile(fileKey, JSON.parse(mergedContent), setAppFiles);
                        } else {
                            const baseData = JSON.stringify(defaultDataMap[fileKey]);
                            const mergedContent = mergeKeyValueUpdates(baseData, content);
                            updateFile(fileKey, JSON.parse(mergedContent), setAppFiles);
                        }
                    } else if (replaceMode === 'merge') {
                        const existingItems = existingContent ? JSON.parse(existingContent) : (defaultDataMap[fileKey] || []);
                        const newItems = JSON.parse(content);

                        const existingKeys = new Set(existingItems.map(item => item.Key));

                        const uniqueNewItems = newItems.filter(item => !existingKeys.has(item.Key));

                        const mergedItems = [...existingItems, ...uniqueNewItems];

                        updateFile(fileKey, mergedItems, setAppFiles);
                    } else {
                        updateFile(fileKey, JSON.parse(content), setAppFiles);
                    }
                } catch (error) {
                    console.error(`Error processing file ${relativePath}:`, error);
                }
            }
        }

        return 'Files updated successfully.';
    }


    const handleReplaceData = async () => {
        if (!file) {
            setError('Please select a ZIP file');
            return;
        }

        setError('');
        try {
            console.log("Starting to unpack ZIP");
            const zip = await unpackZipInMemory(file);
            console.log("ZIP unpacked successfully");
            const result = await compareAndReplaceFiles(zip, setAppFiles);
            alert(result);
            handleClose();
        } catch (error) {
            setError(`Error: ${error.message}`);
        }
        window.location.reload(false);
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Import Custom Data
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Replace Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a ZIP file containing the replacement data files. Choose whether to replace entire files or just update specific descriptions.
                    </DialogContentText>
                    <input
                        accept=".zip"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="raised-button-file" sx={{ paddingTop: "15px" }}>
                        <Button variant="raised" component="span">
                            Choose ZIP File
                        </Button>
                    </label>
                    {file && <Typography>{file.name}</Typography>}
                    {error && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <FormControl component="fieldset" sx={{ paddingLeft: "20px" }}>
                    <RadioGroup
                        value={replaceMode}
                        onChange={(e) => setReplaceMode(e.target.value)}
                    >
                        <FormControlLabel value="whole" control={<Radio />} label="Replace all data" />
                        <FormControlLabel value="keyValue" control={<Radio />} label="Replace only descriptions" />
                        <FormControlLabel value="merge" control={<Radio />} label="Merge datasets" />
                    </RadioGroup>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleReplaceData} disabled={!file}>
                        Replace
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


///************************************COPY***DESCRIPTIONS***IN***BULK************************** */

// import armor from "./data/armor.json"
// import attachments from "./data/attachments.json"
// import callToAdventure from "./data/callToAdventure.json"
// import careers from "./data/careers.json"
// import cultures from "./data/cultures.json"
// import duties from "./data/duties.json"
// import forceExperience from "./data/forceExperience.json"
// import gear from "./data/gear.json"
// import mentors from "./data/mentors.json"
// import moralities from "./data/moralities.json"
// import motivations from "./data/motivations.json"
// import obligations from "./data/obligations.json"
// import species from "./data/species.json"
// import specificMotivations from "./data/specificMotivations.json"
// import vehicles from "./data/vehicles.json"
// import weapons from "./data/weapons.json"

// function updateAndLogImports(appFiles) {
//     const imports = {
//         armor,
//         attachments,
//         callToAdventure,
//         careers,
//         cultures,
//         duties,
//         forceExperience,
//         gear,
//         mentors,
//         moralities,
//         motivations,
//         obligations,
//         species,
//         specificMotivations,
//         vehicles,
//         weapons
//     };

//     Object.entries(imports).forEach(([name, importArray]) => {
//         if (Array.isArray(importArray) && appFiles[name]) {
//             let appFileArray = JSON.parse(appFiles[name])
//             const updatedArray = importArray.map(item => {
//                 const matchingAppFile = appFileArray.find(appItem => appItem.Key === item.Key);
//                 if (matchingAppFile && matchingAppFile.Description) {
//                     return { ...item, Description: matchingAppFile.Description };
//                 }
//                 return item;
//             });

//             console.log(`Updated ${name}:`, updatedArray);

//             // If you want to update the original import arrays, uncomment the next line
//             // imports[name] = updatedArray;
//         } else {
//             console.log(`${name} is not an array or doesn't have a matching AppFile.`);
//         }
//     });
// }

function App({ appFiles, setAppFiles, handleThemeChange }) {
    const handleClearStorage = () => {
        localStorage.clear()
        setAppFiles({})
        window.location.reload(false);
    }
    const [editModalOpen, setEditModalOpen] = useState(false)

    let editFiles = { ...defaultDataMap }
    for (let o in Object.keys(editFiles)) {
        if (appFiles[Object.keys(editFiles)[o]]) {
            editFiles[Object.keys(editFiles)[o]] = appFiles[Object.keys(editFiles)[o]]
        } else {
            editFiles[Object.keys(editFiles)[o]] = JSON.stringify(editFiles[Object.keys(editFiles)[o]])
        }
    }

    // useEffect(() => {
    //     updateAndLogImports(appFiles);
    // }, [appFiles]);

    const handleDataExport = async () => {
        const zip = new JSZip();

        Object.keys(appFiles).forEach((key) => {
            const jsonContent = appFiles[key];
            zip.file(`${key}.json`, jsonContent);
        });

        const zipBlob = await zip.generateAsync({ type: "blob" });

        const url = window.URL.createObjectURL(zipBlob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "LocalData.zip";
        await new Promise((resolve) => setTimeout(resolve, 100));
        link.click();

        window.URL.revokeObjectURL(url);
    };

    return (
        <Container
            style={{
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center"
            }}
        >
            <Typography variant="h2" gutterBottom>Welcome to Hyperdrive Generator</Typography>
            <Typography variant="h5" gutterBottom>A Star Wars Roleplaying Character Generator</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 2 }}>
                <Button onClick={() => window.open('https://www.patreon.com/HyperdriveGenerator', '_blank', 'noopener,noreferrer')}>
                    Support on Patreon
                </Button>
                <Button onClick={() => window.open('https://discord.gg/GyXJZqeUC4', '_blank', 'noopener,noreferrer')}>
                    Join the Discord
                </Button>
            </Box>

            <CardMedia
                height="200"
                component="img"
                className="logoImage"
                image={`${process.env.PUBLIC_URL}/favicon.png`}
                title={'HyperdriveLogo'}
                sx={{ objectFit: "contain", marginBottom: 2 }}
            />

            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <ReplaceDataModal appFiles={appFiles} setAppFiles={setAppFiles} />
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => setEditModalOpen(true)}>Edit Local Data</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => handleDataExport()}>Export Local Data</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => handleClearStorage()}>Clear Local Data</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={handleThemeChange}>Change Theme</Button>
                </Grid>
            </Grid>
            {editModalOpen && (
                <DataEditModal
                    open={editModalOpen}
                    handleClose={() => setEditModalOpen(false)}
                    dataMap={editFiles}
                    appFiles={appFiles}
                    setAppFiles={setAppFiles}
                />
            )}
        </Container>
    );
}

export default App;
