import { Paper, Typography, TextField, Autocomplete } from "@mui/material";
import forceAbilities from '../data/forceAbilities.json'

const MentorEdit = ({ editedItem, index, appFiles, setAppFiles }) => {

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = newItem;
        newFiles["mentors"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    return (
        <>
            <Typography>{index}</Typography>

            {index === "Mentor History" && (
                <>
                    <Typography>Training:</Typography>
                    {editedItem.MentorHistory.Training.map((train, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Title"
                                value={train.Title}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.Training[idx].Title = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Description"
                                fullWidth
                                value={train.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.Training[idx].Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                        </Paper>
                    ))}

                    <Typography>The Clone Wars</Typography>
                    {editedItem.MentorHistory.TheCloneWars.map((tcw, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Title"
                                value={tcw.Title}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.TheCloneWars[idx].Title = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Description"
                                fullWidth
                                value={tcw.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.TheCloneWars[idx].Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                        </Paper>
                    ))}

                    <Typography>Surviving the Purge</Typography>
                    {editedItem.MentorHistory.SurvivingThePurge.map((stp, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Title"
                                value={stp.Title}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.SurvivingThePurge[idx].Title = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Description"
                                fullWidth
                                value={stp.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorHistory.SurvivingThePurge[idx].Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                        </Paper>
                    ))}
                </>
            )}

            {index === "Mentor Personality" && (
                <>
                    {editedItem.MentorPersonality.map((personality, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Style"
                                value={personality.Style}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorPersonality[idx].Style = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Details"
                                fullWidth
                                value={personality.Details}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorPersonality[idx].Details = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                        </Paper>
                    ))}
                </>
            )}

            {index === "Mentor Complications" && (
                <>
                    {editedItem.MentorComplications.map((complication, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Complication"
                                value={complication.Complication}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorComplications[idx].Complication = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Details"
                                fullWidth
                                value={complication.Details}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorComplications[idx].Details = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                        </Paper>
                    ))}
                </>
            )}

            {index === "Mentor Force Traditions" && (
                <>
                    {editedItem.MentorForceTraditions.map((tradition, idx) => (
                        <Paper variant="outlined" sx={{ padding: "10px" }} key={idx}>
                            <p />
                            <TextField
                                label="Tradition"
                                value={tradition.Tradition}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorForceTraditions[idx].Tradition = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Description"
                                fullWidth
                                value={tradition.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorForceTraditions[idx].Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Benefit"
                                fullWidth
                                value={tradition.Benefit.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorForceTraditions[idx].Benefit.Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <Typography>Discounts:</Typography>
                            <p />
                            {tradition.Benefit.Discounts.map((discount, dI) => (<>
                                <p />
                                <Autocomplete
                                    value={forceAbilities.find((ability) => ability.Key === discount.Power) || null}
                                    onChange={(event, newValue) => {
                                        let newMentors = { ...editedItem };
                                        newMentors.MentorForceTraditions[idx].Benefit.Discounts[dI].Power = newValue ? newValue.Key : "";
                                        handleEdit(newMentors);
                                    }}
                                    options={forceAbilities.sort((a, b) => (a.Power + a.Name).localeCompare(b.Power + b.Name))}
                                    getOptionLabel={(option) => option.Power + " " + option.Name}
                                    renderInput={(params) => <TextField {...params} label="Power" />}
                                    sx={{ maxWidth: "500px" }}
                                />

                                <p />
                                <TextField
                                    value={discount.Discount}
                                    onChange={(event) => {
                                        if (!isNaN(event.target.value)) {
                                            let newMentors = { ...editedItem }
                                            newMentors.MentorForceTraditions[idx].Benefit.Discounts[dI].Discount = event.target.value
                                            handleEdit(newMentors);
                                        }
                                    }}
                                />
                                <p />
                            </>))}
                            <p />
                            <TextField
                                label="Drawback"
                                fullWidth
                                value={tradition.Drawback.Description}
                                onChange={(e) => {
                                    let newMentors = { ...editedItem }
                                    newMentors.MentorForceTraditions[idx].Drawback.Description = e.target.value
                                    handleEdit(newMentors)
                                }}
                            />
                            <p />
                            <TextField
                                label="Remove Drawback Cost"
                                value={tradition.Drawback.Cost}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        let newMentors = { ...editedItem }
                                        newMentors.MentorForceTraditions[idx].Drawback.Cost = e.target.value
                                        handleEdit(newMentors)
                                    }
                                }}
                            />
                            <p />
                        </Paper>
                    ))}
                </>
            )}
        </>
    )
}

export default MentorEdit;
