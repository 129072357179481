import { Paper, Typography, TextField, Grid, IconButton, Button, Select, Checkbox, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import skills from '../data/skills.json'

const CareerEdit = ({ editedItem, index, appFiles, setAppFiles }) => {

    const specializations = JSON.parse(appFiles.specializations)

    const handleEdit = (newItem) => {
        let newFiles = { ...appFiles };
        let newCat = JSON.parse(appFiles["careers"]);
        newCat[index] = newItem;
        newFiles["careers"] = JSON.stringify(newCat);
        setAppFiles(newFiles);
        localStorage.setItem('appFiles', JSON.stringify(newFiles));
    };

    return (
        <Paper>
            {editedItem.CareerSkills && (
                <>
                    <Typography>Career Skills:</Typography>
                    <Grid container>
                        {editedItem.CareerSkills.map((cSkill, index) => (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <Select
                                    value={cSkill || ''}
                                    onChange={(e) => {
                                        let newSkills = [...editedItem.CareerSkills];
                                        newSkills[index] = e.target.value;
                                        handleEdit({ ...editedItem, CareerSkills: newSkills });
                                    }}
                                    sx={{ minWidth: "80px" }}
                                >
                                    {skills.sort((a, b) => a.skill.localeCompare(b.skill)).map((skill) => (
                                        <MenuItem key={skill.Key} value={skill.skill}>
                                            {skill.skill}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton onClick={(e) => {
                                    let newCareerSkills = [...editedItem.CareerSkills];
                                    newCareerSkills.splice(index, 1)
                                    handleEdit({ ...editedItem, CareerSkills: newCareerSkills });
                                }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                    <Button onClick={(e) => {
                        let newCareerSkills = [...editedItem.CareerSkills];
                        newCareerSkills.push("")
                        handleEdit({ ...editedItem, CareerSkills: newCareerSkills });
                    }}
                        className="no-print"
                    >
                        Add
                    </Button>
                </>
            )}
            {editedItem.Specializations && (
                <>
                    <Typography>Specializations:</Typography>
                    <Grid container>
                        {editedItem.Specializations.map((cSkill, index) => (
                            <Grid item key={index} sx={{ margin: "7.5px" }}>
                                <Select
                                    value={cSkill || ''}
                                    onChange={(e) => {
                                        let newSkills = [...editedItem.Specializations];
                                        newSkills[index] = e.target.value;
                                        handleEdit({ ...editedItem, Specializations: newSkills });
                                    }}
                                    sx={{ minWidth: "80px" }}
                                >
                                    {specializations.sort((a, b) => a.Name.localeCompare(b.Name)).map((spec) => (
                                        <MenuItem key={spec.Key} value={spec.Key}>
                                            {spec.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <IconButton onClick={(e) => {
                                    let newSpecializations = [...editedItem.Specializations];
                                    newSpecializations.splice(index, 1)
                                    handleEdit({ ...editedItem, Specializations: newSpecializations });
                                }}
                                    sx={{ maxWidth: "30px" }}
                                    size="small"
                                    className="no-print"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                    <Button onClick={(e) => {
                        let newSpecializations = [...editedItem.Specializations];
                        newSpecializations.push("")
                        handleEdit({ ...editedItem, Specializations: newSpecializations });
                    }}
                        className="no-print"
                    >
                        Add
                    </Button>
                </>
            )}
            <br />
            <TextField
                sx={{
                    maxWidth: "150px",
                }}
                label="Free Ranks"
                value={editedItem?.FreeRanks || 0}
                onChange={(e) => { if (!isNaN(e.target.value)) { handleEdit({ ...editedItem, FreeRanks: e.target.value }) } }
                }
            />
            <Typography>Grants Force Rating:</Typography>
            <Checkbox
                checked={editedItem.Attributes?.ForceRating}
                onChange={(e) => {
                    let newAttributes = { ...editedItem.Attributes }
                    if (e.target.checked) {
                        newAttributes.ForceRating = 1
                    } else {
                        delete newAttributes.ForceRating
                    }
                    handleEdit({ ...editedItem, Attributes: newAttributes })
                }}
            />

        </Paper>
    )
}

export default CareerEdit