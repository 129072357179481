import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import {
  Container,
  Grid,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Card,
  CardMedia,
  List,
  ListItem,
  Modal,
  IconButton,
  Checkbox
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import forcePowersData from "./data/forcePowers.json";
import forceAbilitiesData from "./data/forceAbilities.json";
import skillsData from "./data/skills.json";
import weaponsData from "./data/weapons.json";
import warriorRewards from "./data/warriorRewards.json"
import sigAbNodes from "./data/signatureAbilityNodes.json"
import talentsData from "./data/talents.json"
import qualitiesData from "./data/qualities.json"
import MarkedText from "./Util/MarkedText";
import DiceModifiers from "./Util/DiceModifiers";
import FreeRanks from "./Util/FreeRanks";
import getModText from "./Util/GetModText";
import getDicePool from "./Util/GetDicePool";
import getCharacterModifiers from "./Util/GetCharacterModifiers";
import getFullBoughtTalents from "./Util/GetFullBoughtTalents";
import qualitiesString from "./Util/QualitiesString";
import StatForm from "./Util/StatForm";

let forcePowers = forcePowersData
let forceAbilities = forceAbilitiesData
let skills = skillsData
let weapons = weaponsData
let talents = talentsData
let qualities = qualitiesData

function App({ character, handleCharacterChange, appFiles }) {
  let characteristics = {
    Brawn: parseInt(character.Characteristics.Brawn || 0) + (character.Dedications?.Brawn?.length || 0),
    Agility: parseInt(character.Characteristics.Agility || 0) + (character.Dedications?.Agility?.length || 0),
    Intellect: parseInt(character.Characteristics.Intellect || 0) + (character.Dedications?.Intellect?.length || 0),
    Cunning: parseInt(character.Characteristics.Cunning || 0) + (character.Dedications?.Cunning?.length || 0),
    Willpower: parseInt(character.Characteristics.Willpower || 0) + (character.Dedications?.Willpower?.length || 0),
    Presence: parseInt(character.Characteristics.Presence || 0) + (character.Dedications?.Presence?.length || 0),
  }

  if (character.Modifiers && characteristics.Brawn) {
    let charMods = character.Modifiers.Chars
    let newChars = { ...characteristics }
    if (charMods.BR) { newChars.Brawn = parseInt(characteristics.Brawn) + parseInt(charMods.BR) }
    if (charMods.AG) { newChars.Agility = parseInt(characteristics.Agility) + parseInt(charMods.AG) }
    if (charMods.INT) { newChars.Intellect = parseInt(characteristics.Intellect) + parseInt(charMods.Int) }
    if (charMods.CUN) { newChars.Cunning = parseInt(characteristics.Cunning) + parseInt(charMods.CUN) }
    if (charMods.WILL) { newChars.Willpower = parseInt(characteristics.Willpower) + parseInt(charMods.WILL) }
    if (charMods.PR) { newChars.Presence = parseInt(characteristics.Presence) + parseInt(charMods.PR) }
    if (JSON.stringify(newChars) !== JSON.stringify(characteristics)) {
      characteristics = newChars
    }
  }

  useEffect(() => {
    if (appFiles.skills) { skills = JSON.parse(appFiles.skills) }
    if (appFiles.weapons) { weapons = JSON.parse(appFiles.weapons) }
    if (appFiles.forcePowers) { forcePowers = JSON.parse(appFiles.forcePowers) }
    if (appFiles.forceAbilities) { forceAbilities = JSON.parse(appFiles.forceAbilities) }
    if (appFiles.talents) { talents = JSON.parse(appFiles.talents) }
    if (appFiles.qualities) { qualities = JSON.parse(appFiles.qualities) }
  }, [appFiles.weapons, appFiles.skills, appFiles.forceAbilities, appFiles.forcePowers, appFiles.talents, appFiles.qualities]);

  const [textEntry, setTextEntry] = useState(character.Background.Text);
  const [characterName, setCharacterName] = useState(character.Name);
  const [speciesName, setSpeciesName] = useState(character.Species.Name);
  const [defense, setDefense] = useState(character.Defense);
  const [encumbrance, setEncumbrance] = useState(character.EncumbranceThreshold);
  const [encumbranceCurrent, setEncumbranceCurrent] = useState(character.EncumbranceCurrent);
  const [woundsCurrent, setWoundsCurrent] = useState(character.WoundsCurrent || "")
  const [strainCurrent, setStrainCurrent] = useState(character.StrainCurrent || "")
  const [imageUrl, setImageUrl] = useState(character.Image || "");
  const [earnedXP, setEarnedXP] = useState(character.EarnedXP || 0);
  const [charPowers, setCharPowers] = useState([]);
  const [notes, setNotes] = useState(character.Notes || "")

  const updateNotes = (string) => {
    setNotes(string)
    handleCharacterChange({ Notes: string })
  }

  const dieModifiers = DiceModifiers(character, handleCharacterChange, talents, forcePowers, forceAbilities, skills);

  useEffect(() => {
    let modifiers = getCharacterModifiers(character, skills, qualities)
    if (JSON.stringify(modifiers) !== JSON.stringify(character.Modifiers)) {
      handleCharacterChange({ Modifiers: modifiers });
    }
  }, [character])

  const { Bought: boughtTalents, universalForce } = getFullBoughtTalents(character);

  if (JSON.stringify(character.BoughtTalents) !== JSON.stringify(boughtTalents)) {
    handleCharacterChange({ BoughtTalents: boughtTalents })
  }

  if (JSON.stringify(character.BoughtPowers) !== JSON.stringify(charPowers)) {
    handleCharacterChange({ BoughtPowers: charPowers })
  }

  const boughtSignatureAbilities = () => {
    let abilityArray = []
    for (let a in character.SignatureAbilities) {
      let ab = character.SignatureAbilities[a]
      for (let r in ab.AbilityRows) {
        let row = ab.AbilityRows[r]
        for (let sa in row.Abilities) {
          let sAb = row.Abilities[sa]
          if (ab.BoughtTalents[r][sa]) {
            if (abilityArray.find((a) => a.Key === sAb)) {
              abilityArray.find((a) => a.Key === sAb).Count += 1
            }
            else {
              let newNode = sigAbNodes.find((n) => n.Key === sAb)
              newNode.Count = 1
              abilityArray.push(newNode)
            }
          }
        }
      }
    }
    return abilityArray
  }

  function getFullBoughtPowers() {
    const boughtPowers = [];
    const powerMap = {};

    for (let key in character.ForcePowers) {
      let power = forcePowers.find((p) => p.Key === key);
      power.BoughtPowers = character.ForcePowers[key];
      if (!power.AbilityRows) {
        return [];
      }
      power.AbilityRows.forEach((row, rowIndex) => {
        row.Abilities.forEach((powerKey, index) => {
          if (power.BoughtPowers[rowIndex][index]) {
            if (!powerMap[powerKey]) {
              const powerData = forceAbilities.find((p) => p.Key === powerKey);
              powerMap[powerKey] = powerData;
            }

            const existingPower = boughtPowers.find((p) => p.key === powerKey);
            if (existingPower) {
              existingPower.count++;
            } else {
              boughtPowers.push({
                key: powerKey,
                data: powerMap[powerKey],
                count: 1,
              });
            }
          }
        });
      });
    }
    handleCharacterChange({ BoughtPowers: boughtPowers });
    return boughtPowers;
  }

  useEffect(() => {
    setCharPowers(getFullBoughtPowers());
  }, []);

  const earnedChange = (event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let currentXP = character.XP || 0;
    let currentEarned = character.EarnedXP || 0;
    let newTotal = currentXP + (event.target.value - currentEarned);
    setEarnedXP(event.target.value);
    handleCharacterChange({ EarnedXP: event.target.value, XP: newTotal });
  };

  const obligationChange = (event, index) => {
    const newValue = event.target.value;
    if (newValue !== '' && isNaN(newValue)) {
      return;
    }

    let newObligations = [...character.Obligations];
    newObligations[index].Total = newValue === '' ? '' : parseInt(newValue);

    handleCharacterChange({ Obligations: newObligations });
  };

  const dutyChange = (event, index) => {
    const newValue = event.target.value;
    if (newValue !== '' && isNaN(newValue)) {
      return;
    }

    let newDuties = [...character.Duties];
    newDuties[index].Total = newValue === '' ? '' : parseInt(newValue);

    handleCharacterChange({ Duties: newDuties });
  };

  const moralityChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== '' && isNaN(newValue)) {
      return;
    }

    let newMorality = { ...character.Morality }
    newMorality.Score = newValue === '' ? '' : parseInt(newValue);

    handleCharacterChange({ Morality: newMorality });
  };

  const handleImageChange = (event) => {
    setImageUrl(event.target.value);
    handleCharacterChange({ Image: event.target.value });
  };

  let toughened = 0;
  if (boughtTalents) {
    toughened = boughtTalents.find((t) => t.key === "TOUGH")
      ? boughtTalents.find((t) => t.key === "TOUGH").count
      : 0;
  }
  let newWounds =
    parseInt(character.Species.StartingAttrs.WoundThreshold) +
    parseInt(characteristics.Brawn) +
    toughened * 2;
  if (character.Species.StartingAttrs.WoundThreshold && (character.Wounds != newWounds)) {
    handleCharacterChange({ Wounds: newWounds });
  }

  let grit = 0;
  if (boughtTalents) {
    grit = boughtTalents.find((t) => t.key === "GRIT")
      ? boughtTalents.find((t) => t.key === "GRIT").count
      : 0;
  }
  let newStrain =
    parseInt(character.Species.StartingAttrs.StrainThreshold) +
    parseInt(characteristics.Willpower) +
    grit;
  if (character.Species.StartingAttrs.StrainThreshold && (character.Strain != newStrain))
    handleCharacterChange({ Strain: newStrain });

  let armor = 0;
  let brawn = parseInt(characteristics.Brawn) || 0;
  if (character.Armor[0]) {
    for (let a in character.Armor) {
      let arm = character.Armor[a]
      if (arm.Equipped && parseInt(arm.Soak) > armor) {
        armor = parseInt(arm.Soak)
        if (boughtTalents) {
          let armorMast = boughtTalents.find((t) => t.key === "ARM")
            ? boughtTalents.find((t) => t.key === "ARM").count
            : 0;
          armor += armorMast
        }
      }
    }
  }
  let enduring = 0;
  if (boughtTalents) {
    enduring = boughtTalents.find((t) => t.key === "ENDUR")
      ? boughtTalents.find((t) => t.key === "ENDUR").count
      : 0;
  }
  if (brawn + armor + enduring !== character.Soak) {
    handleCharacterChange({ Soak: brawn + armor + enduring });
  }

  let ranged = 0;
  let melee = 0;
  if (character.Armor[0]) {
    for (let a in character.Armor) {
      let arm = character.Armor[a]
      if (arm.Equipped) {
        if (arm.Defense && parseInt(arm.Defense) > ranged) {
          ranged = parseInt(arm.Defense || 0);
          melee = parseInt(arm.Defense || 0);
        }
        if (arm.RangedDefense) { ranged += parseInt(arm.RangedDefense) }
        if (arm.MeleeDefense) { melee += parseInt(arm.MeleeDefense) }
      }
    }
    if (armor >= 2) {
      if (boughtTalents) {
        let armImp = boughtTalents.find((t) => t.key === "ARMIMP")
          ? boughtTalents.find((t) => t.key === "ARMIMP").count
          : 0;
        if (armImp) { ranged += 1; melee += 1 }
      }
    }

  }

  let baseUnarmed = {
    Key: "UNARMED",
    Name: "Unarmed",
    DamageAdd: 0,
    Crit: 5,
    SkillKey: "BRAWL",
    Range: "Engaged",
    Qualities: [
      { Key: "KNOCKDOWN" },
      { Key: "STUNSETTING" }
    ]
  }
  let speciesWeapon = character.Species.WeaponModifier || character.Species.SubSpeciesList?.find((ss) => ss.Key === character.Species.SubSpecies,)?.WeaponModifier || undefined

  useEffect(() => {
    if (speciesWeapon && !speciesWeapon.Secondary) {
      baseUnarmed.Name = speciesWeapon.UnarmedName || "Unarmed"
      baseUnarmed.DamageAdd = speciesWeapon.DamageAdd || 0
      baseUnarmed.Crit = speciesWeapon.Crit || 5
      baseUnarmed.Qualities = baseUnarmed.Qualities.concat(speciesWeapon.Qualities || [])
    }

    let prevWeapons = [...character.Weapons]
    if (prevWeapons.find((w) => w.Key === "UNARMED")) {
      if (JSON.stringify(baseUnarmed) !== JSON.stringify(prevWeapons.find((w) => w.Key === "UNARMED"))) {
        let index = prevWeapons.findIndex((w) => w.Key === "UNARMED")
        prevWeapons[index] = baseUnarmed
        handleCharacterChange({ Weapons: prevWeapons })
      }
    } else {
      prevWeapons.push(baseUnarmed)
      handleCharacterChange({ Weapons: prevWeapons })
    }
  }, [speciesName])

  let sixSense = 0
  let supRef = 0
  let defTrain = 0
  let burly = 0
  let itemModTalents = []
  if (boughtTalents) {
    sixSense = boughtTalents.find((t) => t.key === "SIXSENSE")
      ? boughtTalents.find((t) => t.key === "SIXSENSE").count
      : 0;
    supRef = boughtTalents.find((t) => t.key === "SUPREF")
      ? boughtTalents.find((t) => t.key === "SUPREF").count
      : 0;
    defTrain = boughtTalents.find((t) => t.key === "DEFTRAIN")
      ? boughtTalents.find((t) => t.key === "DEFTRAIN").count
      : 0;
    burly = boughtTalents.find((t) => t.key === "BURLY")
      ? boughtTalents.find((t) => t.key === "BURLY").count
      : 0;
    if (sixSense > 0) { ranged += 1 }
    if (supRef > 0) { melee += 1 }
    itemModTalents = boughtTalents.filter((t) => t.data.ItemChanges)
  }

  let allWeapons = [...character.Weapons]

  for (let w in allWeapons) {
    let weap = JSON.parse(JSON.stringify({ ...allWeapons[w] }))
    if (!weap.TalentMods) { weap.TalentMods = {} }

    if (weap.SkillKey === "BRAWL") {
      let unarmed = allWeapons.find(w => w.Key === "UNARMED")
      if (unarmed) {
        if (weap.Crit > unarmed.Crit) {
          weap.TalentMods.Crit = weap.Crit
          weap.Crit = unarmed.Crit
        }
        if (weap.Crit < unarmed.Crit && weap.Crit < weap.TalentMods.Crit) {
          weap.TalentMods.CritCorrection = `${Date.now()}`
          weap.Crit = (Math.min(unarmed.Crit && weap.TalentMods.Crit))
        }
        if (!weap.Qualities) { weap.Qualities = unarmed.Qualities } else {
          weap.Qualities = [...weap.Qualities, ...unarmed.Qualities].reduce((acc, current) => {
            const existingQuality = acc.find((q) => q.Key === current.Key);
            if (existingQuality) {
              if (existingQuality.Count != null && current.Count != null) {
                existingQuality.Count = Math.max(existingQuality.Count, current.Count);
              }
              else if (current.Count != null) {
                existingQuality.Count = current.Count;
              }
            } else {
              acc.push(current);
            }
            weap.TalentMods.UnarmedQualities = unarmed.Qualities
            return acc;
          }, []);

        }
      }
    }
    if (burly > 0) {
      if (burly !== weap.TalentMods?.Burly) {
        const burlyDifference = burly - (weap.TalentMods?.Burly || 0);
        weap.Encumbrance = Math.max(weap.Encumbrance - burlyDifference, 1);
        let qual = weap.Qualities.find((q) => q.Key === "CUMBERSOME");
        if (qual) {
          qual.Count = Math.max(parseInt(qual.Count) - burlyDifference, 1);
        }
        weap.TalentMods.Burly = burly;
      }
    }
    if (!weap.TalentMods) { weap.TalentMods = {} }
    if (weap.Equipped) {
      if ((weap.SkillKey === "BRAWL" || weap.SkillKey === "MELEE" || weap.SkillKey === "LTSABER") && defTrain > 0 && defTrain !== weap.TalentMods?.Defensive) {
        if (weap.Qualities && weap.Qualities.find((q) => q.Key === "DEFENSIVE")) {
          weap.Qualities.find((q) => q.Key === "DEFENSIVE").Count += defTrain
        }
        else {
          if (weap.Qualities) {
            weap.Qualities.push({ Key: "DEFENSIVE", Count: defTrain })
          }
          else { weap.Qualities = [{ Key: "DEFENSIVE", Count: defTrain }] }
        }
      }
    }
    if (weap.Qualities) {
      for (let q in weap.Qualities) {
        let qual = weap.Qualities[q]
        if (qual.Key === "DEFENSIVE") {
          melee += parseInt(qual.Count)
          weap.TalentMods.Defensive = qual.Count
        }
        if (qual.Key === "DEFLECTION") {
          ranged += parseInt(qual.Count)
          weap.TalentMods.Deflection = qual.Count
        }

      }
    }
    for (let mt in itemModTalents) {
      let modTalent = itemModTalents[mt]
      for (let m in modTalent.data.ItemChanges) {
        let mod = modTalent.data.ItemChanges[m];
        if ((!mod.SkillKey || (weap.SkillKey === mod.SkillKey)) && (!mod.ItemProperty || weap[mod.ItemProperty]) && (!mod.ItemKey || mod.ItemKey === weap.Key)) {

          if (mod.QualityChanges) {
            for (let qc in mod.QualityChanges) {
              if (!weap.Qualities) { weap.Qualities = [] }
              let qualityChange = mod.QualityChanges[qc];
              let forceRating = parseInt(character.ForceRating) || 0;

              let existingQuality = weap.Qualities.find((q) => q.Key === qualityChange.Key);
              let newCount = (parseInt(qualityChange.Count) || (qualityChange.UseForceRating ? parseInt(forceRating) : 0)) * modTalent.count;
              if (existingQuality && !mod.AddQuality) {
                existingQuality.Count = parseInt(existingQuality.Count) + newCount;
                weap.TalentMods[qualityChange.Key] = newCount;
              } else if (mod.AddQuality) {
                if (existingQuality) {
                  existingQuality.Count = newCount;
                  weap.TalentMods[qualityChange.Key] = newCount;
                } else {
                  weap.Qualities.push({ Key: qualityChange.Key, Count: newCount });
                  weap.TalentMods[qualityChange.Key] = newCount;
                }
              }
            }
          }

          if (mod.DamageChange) {
            weap.Damage ? weap.Damage = parseInt(weap.Damage) + parseInt(mod.DamageChange) * modTalent.count :
              weap.DamageAdd = parseInt(weap.DamageAdd) + parseInt(mod.DamageChange) * modTalent.count;
          }

          if (mod.CritChange) {
            weap.Crit = Math.max(parseInt(weap.Crit) + parseInt(mod.CritChange) * modTalent.count, 1);
          }
        }
      }
    }

    if (JSON.stringify(allWeapons[w]?.TalentMods) !== JSON.stringify(weap.TalentMods)) {
      let newWeapons = [...allWeapons]
      newWeapons[w] = weap
      handleCharacterChange({ Weapons: newWeapons })
    }
  }

  if (ranged != defense.Ranged || melee != defense.Melee) {
    setDefense({ Ranged: ranged, Melee: melee });
    handleCharacterChange({ Defense: { Ranged: ranged, Melee: melee } })
  }

  if (character.Modifiers) {
    let encCalc
    if (characteristics.Brawn) {
      encCalc = (5 + parseInt(characteristics.Brawn || 0) + parseInt(character.Modifiers.Stats.ENCTADD || 0) + parseInt(character.Modifiers.Stats.ENCTBRADD || 0) + (3 * parseInt(character.Modifiers.Stats.ENTADD3 || 0)))
    }
    if (encumbrance !== encCalc) {
      setEncumbrance(encCalc);
    }
  }

  let currentEncumbrance = 0
  for (let w in character.Weapons) {
    if (character.Weapons[w].Carried) {
      currentEncumbrance += parseInt(character.Weapons[w].Encumbrance || 0)
    }
  }
  for (let g in character.Gear) {
    if (character.Gear[g].Carried) {
      currentEncumbrance += Math.floor(parseInt(character.Gear[g].Quantity || 1) / 10) + parseInt(character.Gear[g].Encumbrance || 0);
    }
  }
  for (let a in character.Armor) {
    if (character.Armor[a].Carried) {
      let armorEnc = parseInt(character.Armor[a].Encumbrance || 0)
      if (character.Armor[a].Equipped) {
        armorEnc = armorEnc - 3
        if (armorEnc < 0) { armorEnc = 0 }
      }
      currentEncumbrance += armorEnc
    }
  }
  if (encumbranceCurrent !== currentEncumbrance) {
    setEncumbranceCurrent(currentEncumbrance)
    handleCharacterChange({
      EncumbranceCurrent: currentEncumbrance
    })
  }

  if (
    universalForce === 1 ||
    (character.Career.Attributes && character.Career.Attributes.ForceRating) ||
    (boughtTalents && boughtTalents.find((t) => t.key === "WITCHCCOTR"))
  ) {
    let FR = 1;
    if (character.Extras && character.Extras.ShortPath) { FR += 1 }
    if (boughtTalents && boughtTalents.find((t) => t.key === "FORCERAT")) {
      FR += boughtTalents.find((t) => t.key === "FORCERAT").count;
    }
    if (FR !== character.ForceRating) {
      handleCharacterChange({ ForceRating: FR });
    }
  } else if (character.ForceRating !== 0) {
    handleCharacterChange({ ForceRating: 0 });
  }

  const handleSave = async () => {
    const blob = new Blob([JSON.stringify(character)], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    let name = character.Name;
    name = name.replace(/[^0-9a-zA-Z\-_\.]/g, "");
    link.download = `${name || "character"}.json`;
    await new Promise((resolve) => setTimeout(resolve, 100));
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const handleLoad = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const loadedCharacter = JSON.parse(event.target.result);
      setCharacterName(loadedCharacter.Name || "");
      setSpeciesName(loadedCharacter.Species.Name || "")
      setEarnedXP(loadedCharacter.EarnedXP || 0)
      setCurrentWounds(loadedCharacter.WoundsCurrent || 0)
      setCurrentStrain(loadedCharacter.StrainCurrent || 0)
      setImageUrl(loadedCharacter.Image)
      handleCharacterChange(loadedCharacter);
    };

    reader.readAsText(file);
  };

  const changeName = (event) => {
    setCharacterName(event.target.value);
    handleCharacterChange({ Name: event.target.value });
  };

  const changeSpeciesName = (event) => {
    let species = character.Species;
    species.Name = event.target.value;
    setSpeciesName(event.target.value);
    handleCharacterChange({
      Species: species,
    });
  };

  const handleTextEntryChange = (event) => {
    let background = { ...character.Background };
    background.Text = event.target.value;
    setTextEntry(event.target.value);
    handleCharacterChange({
      Background: background,
    });
  };

  function TalentsTable() {
    return (
      <Paper variant="outlined">
        <Typography variant="h6" style={{ textAlign: "center", width: "100%" }}>
          Talents
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Activation</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boughtTalents.map((talent, index) => (
              <TableRow key={talent.data.Key}>
                <TableCell>{talent.data.Name}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{talent.count}</TableCell>
                <TableCell>{talent.data.ActivationValue}</TableCell>
                <TableCell>
                  <div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(talent.data.Description),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  function SignatureAbilitiesTable() {
    return (
      <Paper variant="outlined">
        <Typography variant="h6" style={{ textAlign: "center", width: "100%" }}>
          SignatureAbilities
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boughtSignatureAbilities().map((ability, index) => (
              <TableRow key={ability.Key}>
                <TableCell>{ability.Name}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{ability.Count}</TableCell>
                <TableCell>
                  <div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(ability.Description),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  function ForceTable() {
    return (
      <Paper variant="outlined">
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", width: "100%" }}
            >
              Force Rating
            </Typography>
            <Typography
              variant="h5"
              style={{ textAlign: "center", width: "100%" }}
            >
              {character.ForceRating}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              style={{ textAlign: "center", width: "100%" }}
            >
              Force Powers
            </Typography>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charPowers.map((power, index) => (
              <TableRow key={power.Key + index}>
                <TableCell>{power.data.Name}</TableCell>
                <TableCell>{power.count}</TableCell>
                <TableCell>
                  <div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(power.data.Description),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  function isCareerSkill(skillName) {
    if (
      character.CareerSkills.includes(skillName) ||
      character.SpecSkills.includes(skillName) ||
      character.ExtraCareerSkills.includes(skillName) ||
      character.SelectedCareerSkills[skillName]
    ) {
      return true;
    } else return false;
  }
  const freeRanks = FreeRanks(character);

  const getTotalRanks = (skill) => {
    let totalRanks = skill.value || 0;
    if (freeRanks[skill.Key]) {
      totalRanks += freeRanks[skill.Key];
    }
    return totalRanks;
  };

  function SkillsTable() {
    const [allSkills, setAllSkills] = React.useState([]);
    const skillsData = [...character.Skills];
    React.useLayoutEffect(() => {
      const groupedSkills = {};
      skillsData.forEach((skill) => {
        if (!groupedSkills[skill.type]) {
          groupedSkills[skill.type] = [];
        }
        groupedSkills[skill.type].push(skill);
      });

      Object.keys(groupedSkills).forEach((type) => {
        groupedSkills[type] = groupedSkills[type].sort((a, b) =>
          a.skill.localeCompare(b.skill),
        );
      });

      const sortedSkills = Object.values(groupedSkills).flat();

      const sortedGeneralSkills = sortedSkills.filter(
        (skill) => skill.type === "General",
      );
      const sortedNonGeneralSkills = sortedSkills.filter(
        (skill) => skill.type !== "General",
      );

      setAllSkills([...sortedGeneralSkills, ...sortedNonGeneralSkills]);
    }, []);

    const splitLength = Math.ceil(allSkills.length / 2);
    const leftSkills = allSkills.slice(0, splitLength);
    const rightSkills = allSkills.slice(splitLength);
    return (
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%", height: "500px" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Skill</TableCell>
                  <TableCell>Char</TableCell>
                  <TableCell>Career</TableCell>
                  <TableCell>Ranks</TableCell>
                  <TableCell>Dice Pool</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leftSkills.map((row) => (
                  <TableRow key={row.Key}>
                    <TableCell>{row.skill}</TableCell>
                    <TableCell>{row.ModChar ? row.ModChar : row.characteristic}</TableCell>
                    <TableCell>
                      {isCareerSkill(row.skill)
                        ? "✔"
                        : ""}
                    </TableCell>
                    <TableCell align="center">{getTotalRanks(row)}</TableCell>
                    <TableCell>
                      {getDicePool(
                        character,
                        characteristics[row.ModChar ? row.ModChar : row.characteristic],
                        getTotalRanks(row) || 0,
                        row,
                        dieModifiers
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Skill</TableCell>
                  <TableCell>Char</TableCell>
                  <TableCell>Career</TableCell>
                  <TableCell>Ranks</TableCell>
                  <TableCell>Dice Pool</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rightSkills.map((row) => (
                  <TableRow key={row.Key}>
                    <TableCell>{row.skill}</TableCell>
                    <TableCell>{row.ModChar ? row.ModChar : row.characteristic}</TableCell>
                    <TableCell>
                      {isCareerSkill(row.skill)
                        ? "✔"
                        : ""}
                    </TableCell>
                    <TableCell align="center">{getTotalRanks(row)}</TableCell>
                    <TableCell>
                      {getDicePool(
                        character,
                        characteristics[row.ModChar ? row.ModChar : row.characteristic],
                        getTotalRanks(row) || 0,
                        row,
                        dieModifiers
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  const critSymbols = (count) => {
    let critPool = "[AD]".repeat(parseInt(count));
    return critPool;
  };

  const WeaponDetails = (key) => {
    if (!key.item) { return null }
    let weapon = weapons.find((w) => w.Key === key.item)
    if (weapon.Qualities) {
      for (let q in weapon.Qualities) {
        let quality = weapon.Qualities[q]
        quality = quality.Key ? qualities.find((qu) => qu.Key === quality.Key).Name.replace("Quality", "") + (quality.Count || '') : quality || ''
        weapon.Qualities[q] = quality
      }
    }

    return (
      <Paper className="p-4">
        <Typography variant="h6" className="mb-2">{weapon.Name}</Typography>
        <Typography className="mb-1">Skill: {skills.find((s) => s.Key === weapon.SkillKey).skill}</Typography>
        <Typography className="mb-1">Damage: {weapon.Damage}</Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: MarkedText.renderer(
              `Critical: ${critSymbols(weapon.Crit)}`,
            ),
          }}
        />
        <Typography className="mb-1">Range: {weapon.Range}</Typography>
        {weapon.Qualities && (
          <div
            dangerouslySetInnerHTML={{
              __html: MarkedText.renderer(
                `${qualitiesString(
                  weapon.Qualities,
                )}`
              ),
            }}
          />
        )}
      </Paper>
    );
  };



  function GearDetails({ array, category }) {
    const [openModal, setOpenModal] = useState(false);
    const [editedItem, setEditedItem] = useState(null);

    const handleOpenModal = (item) => {
      setEditedItem(item);
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const handleSaveItem = (item, index) => {
      let newCategory = [...character[category]];
      newCategory[index] = item;
      handleCharacterChange({ [category]: newCategory });
      handleCloseModal();
    };

    let foundAdversary = ""
    if (editedItem) {
      foundAdversary = editedItem.Profile
    }

    return array.map((previewItem, index) => (
      <Paper
        key={previewItem.Key + index}
        variant="outlined"
        style={{ minHeight: "20vh" }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h5" style={{ padding: "15px" }}>
              {previewItem.Name}
            </Typography>
          </Grid>
          <Grid item>
            {((!previewItem.Characteristics && !"Droids Riding Beasts Trainable Beasts".includes(previewItem.Type)) && !previewItem.Key === "UNARMED" || previewItem.Encumbrance) && <FormControlLabel
              control={
                <Checkbox
                  checked={previewItem.Carried}
                  onChange={(event) => {
                    const isCarried = event.target.checked;
                    previewItem.Carried = isCarried
                    let newCategory = [...character[category]]
                    newCategory[newCategory.findIndex((i) => i.inventoryID === previewItem.inventoryID)] = previewItem;
                    handleCharacterChange({
                      [category]: newCategory
                    });
                  }}
                />
              }
              label="Carried"
            />}
            {!previewItem.Characteristics && !"Droids Riding Beasts Trainable Beasts".includes(previewItem.Type) && !(previewItem.Key === "UNARMED") && <FormControlLabel
              control={
                <Checkbox
                  checked={previewItem.Equipped}
                  onChange={(event) => {
                    const isEquipped = event.target.checked;
                    previewItem.Equipped = isEquipped
                    if (isEquipped) { previewItem.Carried = true }
                    let newCategory = [...character[category]]
                    newCategory[newCategory.findIndex((i) => i.inventoryID === previewItem.inventoryID)] = previewItem;
                    handleCharacterChange({
                      [category]: newCategory
                    });
                  }}
                />
              }
              label="Equipped"
            />}
            <Button onClick={() => handleOpenModal(previewItem)} className="no-print">Edit</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <List>
              {previewItem.Price > 0 && (
                <ListItem>{`Price: ${previewItem.Price} credits`}</ListItem>
              )}
              {previewItem.Rarity === 0 ?
                <ListItem>Rarity: 0</ListItem> :
                parseInt(previewItem.Rarity) > 0 ? <ListItem>Rarity: {previewItem.Rarity}</ListItem> :
                  ''}
              {previewItem.Encumbrance === 0 ?
                <ListItem>Encumbrance: 0</ListItem> :
                previewItem.Encumbrance ? <ListItem>Encumbrance: {previewItem.Encumbrance}</ListItem> :
                  ''}
              {previewItem.Damage && previewItem.DamageAdd && (
                <ListItem>
                  {`Damage: ${previewItem.Damage + " + " + previewItem.DamageAdd
                    }`}
                </ListItem>
              )}
              {previewItem.Damage && !previewItem.DamageAdd && (
                <ListItem>{`Damage: ${previewItem.Damage}`}</ListItem>
              )}
              {!previewItem.Damage && (previewItem.DamageAdd || previewItem.DamageAdd === 0) && (
                <ListItem>
                  {previewItem.DamageAdd
                    ? `Damage: Brawn + ${previewItem.DamageAdd}`
                    : `Damage: Brawn + 0`}
                </ListItem>
              )}
              {previewItem.Crit && (
                <div
                  style={{ padding: "15px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(
                      `Critical: ${critSymbols(previewItem.Crit)
                        ? critSymbols(previewItem.Crit)
                        : previewItem.Crit
                      }`,
                    ),
                  }}
                />
              )}
              {previewItem.Qualities && (
                <ListItem>
                  {`${qualitiesString(previewItem.Qualities)}`}
                </ListItem>
              )}
              {previewItem.Range && (
                <ListItem>{`Range: ${previewItem.Range}`}</ListItem>
              )}
              {previewItem.SkillKey && (
                <ListItem>
                  {`Skill: ${skills.find((s) => s.Key === previewItem.SkillKey).skill
                    }`}
                </ListItem>
              )}
              {previewItem.Soak && (
                <ListItem>{`Soak: ${previewItem.Soak}`}</ListItem>
              )}
              {previewItem.Defense && (
                <ListItem>{`Defense: ${previewItem.Defense}`}</ListItem>
              )}
              {previewItem.HP && (
                <ListItem>{`HP: ${(previewItem.HPUsed || 0) + "/" + previewItem.HP
                  }`}</ListItem>
              )}
              {previewItem.Type && (
                <ListItem>{`Type: ${previewItem.Type}`}</ListItem>
              )}
              {previewItem.BaseMods && (
                <>
                  {previewItem.BaseMods.MiscDesc && (<div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(
                        previewItem.BaseMods.MiscDesc,
                      ),
                    }}
                  />)}
                  {previewItem.BaseMods.Key && (
                    <div
                      style={{ padding: "15px" }}
                      dangerouslySetInnerHTML={{
                        __html: MarkedText.renderer(
                          getModText(qualities, talents, skills, previewItem.BaseMods)
                        ),
                      }}
                    />
                  )}
                </>
              )}
              {previewItem.BaseMods &&
                previewItem.BaseMods[0] &&
                previewItem.BaseMods.map((mod, index) => (
                  <div key={mod.Key + index}>
                    {mod.MiscDesc && (<div
                      style={{ padding: "15px" }}
                      dangerouslySetInnerHTML={{
                        __html: MarkedText.renderer(mod.MiscDesc),
                      }}
                    />)}
                    {mod.Key && (
                      <div
                        style={{ padding: "15px" }}
                        dangerouslySetInnerHTML={{
                          __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                        }}
                      />
                    )}
                  </div>
                ))}
              {previewItem.Attachments && (
                <Container>
                  {previewItem.Attachments[0] && (
                    <Typography variant="h6">Attachments</Typography>
                  )}
                  {previewItem.Attachments.map((attachment, attachI) => (
                    <Paper key={attachment.Key + attachI} variant="outlined">
                      <Typography sx={{ mt: "5px" }}>
                        {attachment.Name}
                      </Typography>
                      <Typography sx={{ mt: "5px" }}>
                        HP: {attachment.HP}
                      </Typography>
                      {attachment.BaseMods && (
                        <Typography sx={{ mt: "5px" }}>
                          Base Modifiers:{" "}
                          {attachment.BaseMods.map((mod, index) => (
                            <div key={mod.Key + index}>
                              <div
                                style={{ padding: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod.MiscDesc),
                                }}
                              />
                              {mod.Key && (
                                <div
                                  style={{ padding: "5px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                  }}
                                />
                              )}
                            </div>
                          ))}
                        </Typography>
                      )}
                      {attachment.AddedMods && (
                        <Typography sx={{ mt: "5px" }}>
                          Modification Options:{" "}
                          {attachment.AddedMods.map((mod, index) => (
                            <div key={mod.Key + index}>
                              <div
                                style={{ padding: "5px" }}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod.MiscDesc),
                                }}
                              />
                              {mod.Key && (
                                <div
                                  style={{ padding: "5px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                  }}
                                />
                              )}
                            </div>
                          ))}
                        </Typography>
                      )}
                      {attachment.WeaponModifiers && attachment.WeaponModifiers.map((profile) => (
                        <>
                          <Typography>{profile.Name}</Typography>
                          <ListItem><Typography>Damage: {profile.Damage || ("+" + profilte.DamageAdd)}</Typography></ListItem>
                          {profile.Crit && (
                            <div
                              style={{ padding: "15px" }}
                              dangerouslySetInnerHTML={{
                                __html: MarkedText.renderer(
                                  `Critical: ${critSymbols(profile.Crit)
                                    ? critSymbols(profile.Crit)
                                    : profile.Crit
                                  }`,
                                ),
                              }}
                            />
                          )}
                          {profile.Qualities && (
                            <ListItem>
                              {`${qualitiesString(profile.Qualities)}`}
                            </ListItem>
                          )}
                          {profile.Range && (
                            <ListItem>{`Range: ${profile.Range}`}</ListItem>
                          )}
                          {profile.BaseMods && (
                            <ListItem>
                              <Typography sx={{ mt: "5px" }}>
                                Base Modifiers:{" "}
                                {profile.BaseMods.map((mod, index) => (
                                  <div key={mod.Key + index}>
                                    <div
                                      style={{ padding: "5px" }}
                                      dangerouslySetInnerHTML={{
                                        __html: MarkedText.renderer(mod.MiscDesc),
                                      }}
                                    />
                                    {mod.Key && (
                                      <div
                                        style={{ padding: "5px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: MarkedText.renderer(getModText(qualities, talents, skills, mod)),
                                        }}
                                      />
                                    )}
                                  </div>
                                ))}
                              </Typography>
                            </ListItem>
                          )}
                        </>
                      ))}
                    </Paper>
                  ))}
                </Container>
              )}
              {previewItem.Quantity && (
                <ListItem>{`Quantity: ${previewItem.Quantity}`}</ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{ padding: "15px" }}
              dangerouslySetInnerHTML={{
                __html: MarkedText.renderer(previewItem.Description),
              }}
            />
          </Grid>
        </Grid>
        {(() => {
          let foundAdversary
          if (previewItem.Characteristics) {
            foundAdversary = previewItem;
          } else {
            foundAdversary = previewItem.Profile
          }
          return foundAdversary && (
            <ListItem>
              <div style={{ width: "100%", paddingRight: "15px", paddingLeft: "15px", paddingTop: "0px", textAlign: "center" }}>
                <Typography variant="h6">{foundAdversary.AdversaryType}</Typography>
                <Grid container sx={{ textAlign: "center" }}>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Brawn"
                      value={parseInt(foundAdversary.Characteristics.BR) + (foundAdversary.SuperiorHardware?.filter((a) => a === "BR").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Agility"
                      value={parseInt(foundAdversary.Characteristics.AG) + (foundAdversary.SuperiorHardware?.filter((a) => a === "AG").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Intellect"
                      value={parseInt(foundAdversary.Characteristics.INT) + (foundAdversary.SuperiorHardware?.filter((a) => a === "INT").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Cunning"
                      value={parseInt(foundAdversary.Characteristics.CUN) + (foundAdversary.SuperiorHardware?.filter((a) => a === "CUN").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Willpower"
                      value={parseInt(foundAdversary.Characteristics.WIL) + (foundAdversary.SuperiorHardware?.filter((a) => a === "WIL").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <StatForm
                      title="Presence"
                      value={parseInt(foundAdversary.Characteristics.PR) + (foundAdversary.SuperiorHardware?.filter((a) => a === "PR").length || 0)}
                      color="red"
                      sizes={["h6", "h5"]}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ textAlign: "center", display: "flex", alignItems: "center", minHeight: "100%" }} >
                  <Grid item xs={6} md={foundAdversary.Attributes.StrainThreshold ? 3 : 3}>
                    <StatForm
                      title="Wound Threshold"
                      value={foundAdversary.Attributes.WoundThreshold || "-"}
                      color="blue"
                      sizes={["h8", "h5"]}
                    />
                  </Grid>
                  {foundAdversary.Attributes.StrainThreshold && <Grid item xs={6} md={3}>
                    <StatForm
                      title="Strain Threshold"
                      value={foundAdversary.Attributes.StrainThreshold}
                      color="blue"
                      sizes={["h8", "h5"]}
                    />
                  </Grid>}
                  <Grid item xs={foundAdversary.Attributes.StrainThreshold ? 4 : 6} md={3}>
                    <StatForm
                      title="Soak"
                      value={foundAdversary.Attributes.Soak}
                      color="blue"
                      sizes={["h8", "h5"]}
                    />
                  </Grid>
                  <Grid item xs={foundAdversary.Attributes.StrainThreshold ? 8 : 12} md={foundAdversary.Attributes.StrainThreshold ? 3 : 6}>
                    <Typography variant="h8">Defense</Typography>
                    <Grid container sx={{ paddingRight: "5px", paddingLeft: "5px" }}>
                      <Grid item xs={6} sx={{ paddingRight: "2.5px" }}>
                        <StatForm
                          title="Ranged"
                          value={foundAdversary.Attributes.DefenseRanged || foundAdversary.Attributes.Defense ? foundAdversary.Attributes.Defense[1] : 0}
                          color="blue"
                          sizes={["h8", "h5"]}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingLeft: "2.5px" }}>
                        <StatForm
                          title="Melee"
                          value={foundAdversary.Attributes.DefenseMelee || foundAdversary.Attributes.Defense ? foundAdversary.Attributes.Defense[0] : 0}
                          color="blue"
                          sizes={["h8", "h5"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div style={{ textAlign: "left" }}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      {foundAdversary.Skills && <div>
                        <Typography variant="h6" sx={{ paddintTop: "10px" }}>Skills:</Typography>
                        {(Array.isArray(foundAdversary.Skills) && foundAdversary.Skills[0] && foundAdversary.Skills[0].Key) && foundAdversary.Skills.map((skill, index) => (
                          <React.Fragment key={skill.Key}>
                            <Typography variant="h7">
                              {skills.find((s) => s.Key === skill.Key).skill}
                              {foundAdversary.AdversaryType !== "Minion" ? ` ${skill.Rank}` : ""}
                              {index < foundAdversary.Skills.length - 1 ? ", " : ""}
                            </Typography>
                          </React.Fragment>
                        ))}
                        {(Array.isArray(foundAdversary.Skills) && foundAdversary.Skills[0] && !foundAdversary.Skills[0].Key) && foundAdversary.Skills.map((skill, index) => (
                          <React.Fragment key={skill.Key}>
                            <Typography variant="h7">
                              {skill}
                              {(index < foundAdversary.Skills.length - 1) && ", "}
                            </Typography>
                          </React.Fragment>
                        ))}
                        {!Array.isArray(foundAdversary.Skills) && Object.entries(foundAdversary.Skills).map(([skillName, rank], index, arr) => (
                          <React.Fragment key={skillName}>
                            <Typography variant="h7">
                              {skillName}
                              {foundAdversary.AdversaryType !== "Minion" && rank ? ` ${rank}` : ""}
                              {index < arr.length - 1 ? ", " : ""}
                            </Typography>
                          </React.Fragment>
                        ))}
                      </div>}
                      {foundAdversary.Talents && (
                        <div>
                          <Typography variant="h6" sx={{ paddingTop: "10px" }}>Talents:</Typography>
                          {foundAdversary.Talents.map((talent, index) => {
                            const talentObject = talent.Key
                              ? talents.find((t) => t.Key === talent.Key)
                              : talents.find((t) => talent.includes(t.Name));

                            const talentName = talent.Key
                              ? `${talentObject?.Name} ${parseInt(talent.Ranks)}`
                              : talent;

                            return (
                              <React.Fragment key={talent.Key || talent + index}>
                                <Typography variant="h7">
                                  {talentName}
                                  {index < foundAdversary.Talents.length - 1 ? ", " : ""}
                                </Typography>
                                {talentObject?.Description && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: MarkedText.renderer(talentObject.Description)
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                      {(foundAdversary.Abilities) && (
                        <>
                          <Typography variant="h6" sx={{ paddintTop: "10px" }}>Abilities:</Typography>
                          <div>
                            {foundAdversary.Abilities?.map((mod, index) => (
                              (<>
                                {mod.Description && <div
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(mod.Name + ": " + mod.Description)
                                  }}
                                />}
                                {!mod.Description && <div
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: MarkedText.renderer(mod)
                                  }}
                                />}
                              </>
                              )
                            ))}
                          </div>
                        </>
                      )}

                      {foundAdversary.Cyber && <>
                        <Typography variant="h6" sx={{ paddintTop: "10px" }}>{foundAdversary.Cyber.Name}</Typography>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(foundAdversary.Cyber.Description)
                          }}
                        />
                      </>}
                      {foundAdversary.BaseMods && Array.isArray(foundAdversary.BaseMods) && (
                        <div>
                          {foundAdversary.BaseMods.map((mod, index) => (
                            (
                              mod.MiscDesc && <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer(mod.MiscDesc)
                                }}
                              />
                            )
                          ))}
                        </div>
                      )}
                      {foundAdversary.BaseMods && foundAdversary.BaseMods.MiscDesc &&
                        <div
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(foundAdversary.BaseMods.MiscDesc)
                          }} />}

                      {(foundAdversary.Silhouette === 0 || foundAdversary.Silhouette === 2) && <Typography>Silhouette: {foundAdversary.Silhouette}</Typography>}
                    </Grid>
                    {(foundAdversary.Gear || foundAdversary.Weapons) &&
                      <Grid item xs={12} sm={6} sx={{ paddingLeft: "10px" }}>
                        {foundAdversary.Weapons && foundAdversary.Weapons.map((item, index) => <div key={item + index}>
                          <div style={{ height: "5px" }} />
                          {item.Name && <Paper>
                            <Typography variant="h6">{item.Name}</Typography>
                            <Typography>Skill: {item.Skill}</Typography>
                            <Typography>Damage: {item.Damage}</Typography>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: MarkedText.renderer(
                                  `Critical: ${critSymbols(item.Crit)}`,
                                ),
                              }}
                            />
                            <Typography>Range: {item.Range}</Typography>
                            {item.Qualities && (
                              <Typography> Qualities: {' '}
                                {item.Qualities.map((qual, index) => (
                                  <React.Fragment key={index}>
                                    {qual}
                                    {index < item.Qualities.length - 1 ? ', ' : ''}
                                  </React.Fragment>
                                ))}
                              </Typography>
                            )}
                          </Paper>}
                          {!item.Name && <WeaponDetails item={item} />}
                        </div>)}
                        {foundAdversary.Gear &&
                          <div
                            dangerouslySetInnerHTML={{
                              __html: MarkedText.renderer('Equipment: ' + foundAdversary.Gear)
                            }}
                          />
                        }
                      </Grid>}
                  </Grid>
                </div>
              </div>
            </ListItem>
          );
        })()}

        {openModal && editedItem && editedItem.Key === previewItem.Key && (
          <Modal open={openModal} onClose={handleCloseModal}>
            <Paper
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "25px",
                overflowY: 'auto',
                width: '90vw',
                maxHeight: '90vh',
              }}
            >
              <Typography variant="h6">
                Edit {editedItem ? editedItem.Name : ""}
              </Typography>
              {/* Render input fields for editing */}
              <TextField
                fullWidth
                sx={{
                  marginTop: "10px",
                }}
                label="Name"
                value={editedItem?.Name || ""}
                onChange={(e) =>
                  setEditedItem({ ...editedItem, Name: e.target.value })
                }
              />
              <TextField
                sx={{
                  marginTop: "10px",
                  width: "50%",
                }}
                label="Price"
                value={editedItem?.Price || 0}
                onChange={(e) =>
                  setEditedItem({ ...editedItem, Price: e.target.value })
                }
              />
              <TextField
                sx={{
                  marginTop: "10px",
                  width: "50%",
                }}
                label="Rarity"
                value={editedItem?.Rarity || ""}
                onChange={(e) =>
                  setEditedItem({ ...editedItem, Rarity: e.target.value })
                }
              />
              <TextField
                fullWidth
                sx={{
                  marginTop: "10px",
                }}
                label="Encumbrance"
                value={editedItem?.Encumbrance || ""}
                onChange={(e) =>
                  setEditedItem({ ...editedItem, Encumbrance: e.target.value })
                }
              />
              {category === "Weapons" && (
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: "50%",
                  }}
                  label="Damage"
                  value={editedItem?.Damage || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Damage: e.target.value })
                  }
                />
              )}
              {category === "Weapons" && (
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: "50%",
                  }}
                  label="Crit"
                  value={editedItem?.Crit || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Crit: e.target.value })
                  }
                />
              )}
              {category === "Weapons" && (
                <TextField
                  fullWidth
                  sx={{
                    marginTop: "10px",
                  }}
                  label="Range"
                  value={editedItem?.Range || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Range: e.target.value })
                  }
                />
              )}
              {category === "Armor" && (
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: "50%",
                  }}
                  label="Soak"
                  value={editedItem?.Soak || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Soak: e.target.value })
                  }
                />
              )}
              {category === "Armor" && (
                <TextField
                  sx={{
                    marginTop: "10px",
                    width: "50%",
                  }}
                  label="Defense"
                  value={editedItem?.Defense || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Defense: e.target.value })
                  }
                />
              )}
              {(category === "Armor" || category === "Weapons") && (
                <TextField
                  fullWidth
                  sx={{
                    marginTop: "10px",
                  }}
                  label="HP"
                  value={editedItem?.HP || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, HP: e.target.value })
                  }
                />
              )}
              {(category === "Gear" || category === "Weapons") && (
                <TextField
                  fullWidth
                  sx={{
                    marginTop: "10px",
                  }}
                  label="Type"
                  value={editedItem?.Type || ""}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Type: e.target.value })
                  }
                />
              )}
              {editedItem.Qualities && (
                <>
                  <Typography>Qualities:</Typography>
                  <Grid container>
                    {editedItem.Qualities.map((quality, index) => (
                      <Grid item key={index} sx={{ margin: "7.5px" }}>
                        <TextField
                          label="Quality"
                          value={qualities.find(q => q.Key === quality.Key)?.QualDesc.replace('{0}', '') || quality.Key || ''}
                          onChange={(e) => {
                            let newQualities = [...editedItem.Qualities];
                            newQualities[index].Key = e.target.value;
                            setEditedItem({ ...editedItem, Qualities: newQualities });
                          }}
                          sx={{ maxWidth: "180px" }}
                        />
                        <TextField
                          label="Rating"
                          value={quality.Count || ''}
                          onChange={(e) => {
                            let newQualities = [...editedItem.Qualities];
                            newQualities[index].Count = e.target.value;
                            setEditedItem({ ...editedItem, Qualities: newQualities });
                          }}
                          sx={{ maxWidth: "80px" }}
                        />
                        <IconButton onClick={(e) => {
                          let newQualities = [...editedItem.Qualities];
                          newQualities.splice(index, 1)
                          setEditedItem({ ...editedItem, Qualities: newQualities });
                        }}
                          sx={{ maxWidth: "30px" }}
                          size="small"
                          className="no-print"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    ))}
                  </Grid>
                  <Button onClick={(e) => {
                    let newQualities = [...editedItem.Qualities];
                    newQualities.push({})
                    setEditedItem({ ...editedItem, Qualities: newQualities });
                  }}
                    className="no-print"
                  >
                    Add
                  </Button>
                </>
              )}
              {category === "Gear" && (
                <TextField
                  fullWidth
                  sx={{
                    marginTop: "10px",
                  }}
                  label="Quantity"
                  value={editedItem?.Quantity || 1}
                  onChange={(e) =>
                    setEditedItem({ ...editedItem, Quantity: e.target.value })
                  }
                />
              )}
              {foundAdversary && (<>
                <Grid container>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Brawn"
                      value={editedItem?.Profile?.Characteristics?.BR || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, BR: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Agility"
                      value={editedItem?.Profile?.Characteristics?.AG || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, AG: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Intellect"
                      value={editedItem?.Profile?.Characteristics?.INT || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, INT: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Cunning"
                      value={editedItem?.Profile?.Characteristics?.CUN || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, CUN: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Willpower"
                      value={editedItem?.Profile?.Characteristics?.WIL || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, WIL: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Presence"
                      value={editedItem?.Profile?.Characteristics?.PR || 1}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Characteristics: { ...editedItem.Profile.Characteristics, PR: e.target.value } } })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Wound Threshold"
                      value={editedItem?.Profile?.Attributes?.WoundThreshold || 0}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, WoundThreshold: e.target.value } } })
                      }
                    />
                  </Grid>
                  {(editedItem?.Profile?.Attributes?.StrainThreshold) &&
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        sx={{
                          marginTop: "10px",
                        }}
                        label="Strain Threshold"
                        value={editedItem?.Profile?.Attributes?.StrainThreshold || 0}
                        onChange={(e) =>
                          setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, StrainThreshold: e.target.value } } })
                        }
                      />
                    </Grid>}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      sx={{
                        marginTop: "10px",
                      }}
                      label="Soak"
                      value={editedItem?.Profile?.Attributes?.Soak || 0}
                      onChange={(e) =>
                        setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Attributes: { ...editedItem.Profile.Attributes, Soak: e.target.value } } })
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container>
                      <Grid item xs={4} container alignItems="center" justifyContent="center">
                        <Typography>Defense:</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          sx={{
                            marginTop: "10px",
                          }}
                          label="Ranged"
                          value={
                            (editedItem?.Profile?.Attributes?.Defense &&
                              editedItem.Profile.Attributes.Defense[0]) ||
                            0
                          }
                          onChange={(e) =>
                            setEditedItem({
                              ...editedItem,
                              Profile: {
                                ...editedItem.Profile,
                                Attributes: {
                                  ...editedItem.Profile.Attributes,
                                  Defense: [
                                    e.target.value, // Set Ranged Defense
                                    editedItem?.Profile?.Attributes?.Defense?.[1] || 0, // Keep existing Melee Defense or default to 0
                                  ],
                                },
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          sx={{
                            marginTop: "10px",
                          }}
                          label="Melee"
                          value={
                            (editedItem?.Profile?.Attributes?.Defense &&
                              editedItem.Profile.Attributes.Defense[1]) ||
                            0
                          }
                          onChange={(e) =>
                            setEditedItem({
                              ...editedItem,
                              Profile: {
                                ...editedItem.Profile,
                                Attributes: {
                                  ...editedItem.Profile.Attributes,
                                  Defense: [
                                    editedItem?.Profile?.Attributes?.Defense?.[0] || 0, // Keep existing Ranged Defense or default to 0
                                    e.target.value, // Set Melee Defense
                                  ],
                                },
                              },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {foundAdversary.Skills && (
                  <>
                    <Typography sx={{ marginTop: "10px", }}>Skills:</Typography>
                    <Grid container>
                      {!Array.isArray(editedItem.Profile.Skills) && <> {Object.entries(editedItem.Profile.Skills)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([skillName, ranks], index) => (
                          <Grid item key={index} sx={{ margin: "7.5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <TextField
                              label="Skill"
                              value={skillName || ''}
                              onChange={(e) => {
                                const newSkills = { ...editedItem.Profile.Skills };
                                const newSkillName = e.target.value;
                                newSkills[newSkillName] = newSkills[skillName];
                                delete newSkills[skillName];
                                setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                              }}
                              sx={{ maxWidth: "180px" }}
                            />
                            <TextField
                              label="Ranks"
                              value={ranks || ''}
                              onChange={(e) => {
                                const newSkills = { ...editedItem.Profile.Skills };
                                newSkills[skillName] = parseInt(e.target.value) || 0; // Handle the value change
                                setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                              }}
                              sx={{ maxWidth: "80px" }}
                            />
                            <IconButton
                              onClick={() => {
                                const newSkills = { ...editedItem.Profile.Skills };
                                delete newSkills[skillName]; // Remove the skill
                                setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                              }}
                              sx={{ maxWidth: "30px" }}
                              size="small"
                              className="no-print"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        ))}
                        <Button
                          onClick={() => {
                            const newSkills = { ...editedItem.Profile.Skills };
                            newSkills["New Skill"] = 0;
                            setEditedItem({
                              ...editedItem,
                              Profile: { ...editedItem.Profile, Skills: newSkills }
                            });
                          }}
                          className="no-print"
                        >
                          Add
                        </Button>
                      </>
                      }
                      {Array.isArray(editedItem.Profile.Skills) && <> {editedItem.Profile.Skills.map((skill, index) => (
                        <Grid item key={index} sx={{ margin: "7.5px" }}>
                          <TextField
                            label="Skill"
                            value={skill || ''}
                            onChange={(e) => {
                              let newSkills = [...editedItem.Profile.Skills];
                              newSkills[index] = e.target.value;
                              setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                            }}
                            sx={{ maxWidth: "180px" }}
                          />
                          <IconButton onClick={(e) => {
                            let newSkills = [...editedItem.Skills];
                            newSkills.splice(index, 1)
                            setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Skills: newSkills } });
                          }}
                            sx={{ maxWidth: "30px" }}
                            size="small"
                            className="no-print"
                          >
                            <CloseIcon />
                          </IconButton>

                        </Grid>
                      ))}
                        <Button
                          onClick={() => {
                            const newSkills = [...editedItem.Profile.Skills];
                            newSkills.push("")
                            setEditedItem({
                              ...editedItem,
                              Profile: { ...editedItem.Profile, Skills: newSkills }
                            });
                          }}
                          className="no-print"
                        >
                          Add
                        </Button>
                      </>
                      }
                    </Grid>
                  </>
                )}
                {foundAdversary.Talents && (
                  <>
                    <Typography sx={{ marginTop: "10px", }}>Talents:</Typography>
                    {foundAdversary.Talents.map((talent, index) => (
                      <>
                        <TextField
                          key={index}
                          label="Talent"
                          value={talent.Key ? talents.find((t) => t.Key === talent.Key).Name + " " + talent.Ranks : talent}
                          onChange={(e) => {
                            const newTalents = [...editedItem.Profile.Talents];
                            newTalents[index] = e.target.value;
                            setEditedItem({
                              ...editedItem,
                              Profile: { ...editedItem.Profile, Talents: newTalents },
                            });
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            const newTalents = [...editedItem.Profile.Talents]
                            newTalents.splice(index, 1)
                            setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Talents: newTalents } });
                          }}
                          sx={{ maxWidth: "30px" }}
                          size="small"
                          className="no-print"
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ))}
                    <Button
                      onClick={() => {
                        const newTalents = [...editedItem.Profile.Talents];
                        newTalents.push("")
                        setEditedItem({
                          ...editedItem,
                          Profile: { ...editedItem.Profile, Talents: newTalents }
                        });
                      }}
                      className="no-print"
                    >
                      Add
                    </Button>

                  </>
                )}
                {foundAdversary.Abilities && (
                  <>
                    <Typography sx={{ marginTop: "10px", }}>Abilities:</Typography>
                    {foundAdversary.Abilities.map((ability, index) => (
                      <>
                        <TextField
                          key={index}
                          label="Ability"
                          value={ability.Name ? ability.Name + ": " + ability.Description : ability}
                          onChange={(e) => {
                            const newAbilities = [...editedItem.Profile.Abilities];
                            newAbilities[index] = e.target.value;
                            setEditedItem({
                              ...editedItem,
                              Profile: { ...editedItem.Profile, Abilities: newAbilities },
                            });
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            const newAbilities = [...editedItem.Profile.Abilities]
                            newAbilities.splice(index, 1)
                            setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Abilities: newAbilities } });
                          }}
                          sx={{ maxWidth: "30px" }}
                          size="small"
                          className="no-print"
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ))}
                    <Button
                      onClick={() => {
                        const newAbilities = [...editedItem.Profile.Abilities];
                        newAbilities.push("")
                        setEditedItem({
                          ...editedItem,
                          Profile: { ...editedItem.Profile, Abilities: newAbilities }
                        });
                      }}
                      className="no-print"
                    >
                      Add
                    </Button>

                  </>
                )}
                <>
                  <Grid contianer>
                    <Grid item xs={6}>
                      <Typography sx={{ marginTop: "10px", }}>Weapons:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={() => {
                          const newWeapons = [...editedItem.Profile.Weapons];
                          newWeapons.push({})
                          setEditedItem({
                            ...editedItem,
                            Profile: { ...editedItem.Profile, Weapons: newWeapons }
                          });
                        }}
                        className="no-print"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  {(foundAdversary.Weapons || []).map((weaponObject, index) => {
                    let weapon = typeof weaponObject === 'object' ? weaponObject : weapons.find(w => w.Key === weaponObject);
                    return (
                      <Paper sx={{ marginTop: "10px" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={1} sm={1} md={.2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <IconButton
                              onClick={() => {
                                const newWeapons = [...editedItem.Profile.Weapons || []]
                                newWeapons.splice(index, 1)
                                setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Weapons: newWeapons } });
                              }}
                              sx={{ maxWidth: "30px" }}
                              size="small"
                              className="no-print"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={11} sm={4} md={1} lg={1}>
                            <TextField
                              key={index}
                              label="Name"
                              value={weapon.Name || ''}
                              onChange={(e) => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                weapon.Name = e.target.value
                                newWeapons[index] = weapon
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={1} lg={1}>
                            <TextField
                              key={index}
                              label="Skill"
                              value={weapon.Skill || skills.find((s) => s.Key === weapon.SkillKey)?.skill || ''}
                              onChange={(e) => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                weapon.Skill = e.target.value;
                                newWeapons[index] = weapon
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={1} lg={1}>
                            <TextField
                              key={index}
                              label="Damage"
                              value={weapon.Damage || ''}
                              onChange={(e) => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                weapon.Damage = e.target.value;
                                newWeapons[index] = weapon
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={1} lg={1}>
                            <TextField
                              key={index}
                              label="Crit"
                              value={weapon.Crit || ''}
                              onChange={(e) => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                weapon.Crit = e.target.value;
                                newWeapons[index] = weapon
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={1} lg={1}>
                            <TextField
                              key={index}
                              label="Range"
                              value={weapon.Range || ''}
                              onChange={(e) => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                weapon.Range = e.target.value;
                                newWeapons[index] = weapon
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography>Qualities:</Typography>
                          </Grid>
                          {(weapon.Qualities || []).map((quality, i) => (
                            <>
                              <Grid item xs={12} sm={4} md={1} lg={1} key={i}>
                                <TextField
                                  label="Quality"
                                  value={quality.Key ? qualities.find((q) => q.Key === quality.Key).Name.replace("Quality", "") + (quality.Count || '') : quality || ''}
                                  onChange={(e) => {
                                    const newWeapons = [...editedItem.Profile.Weapons];
                                    weapon.Qualities[i] = e.target.value;
                                    newWeapons[index] = weapon
                                    setEditedItem({
                                      ...editedItem,
                                      Profile: { ...editedItem.Profile, Weapons: newWeapons },
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={1} sm={1} md={.2} >
                                <IconButton
                                  onClick={() => {
                                    const newWeapons = [...editedItem.Profile.Weapons]
                                    newWeapons[index].Qualities.splice(i, 1)
                                    setEditedItem({ ...editedItem, Profile: { ...editedItem.Profile, Weapons: newWeapons } });
                                  }}
                                  sx={{ maxWidth: "30px" }}
                                  size="small"
                                  className="no-print"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </>
                          ))}
                          <Grid item xs={1}>
                            <Button
                              onClick={() => {
                                const newWeapons = [...editedItem.Profile.Weapons];
                                newWeapons[index].Qualities.push('')
                                setEditedItem({
                                  ...editedItem,
                                  Profile: { ...editedItem.Profile, Weapons: newWeapons }
                                });
                              }}
                              className="no-print"
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    )
                  })}

                </>
                <TextField
                  fullWidth
                  sx={{ marginTop: "10px", }}
                  label="Gear"
                  value={foundAdversary.Gear || ''}
                  onChange={(e) => {
                    let newGear = [...editedItem.Profile.Gear || ''];
                    newGear = e.target.value;
                    setEditedItem({
                      ...editedItem,
                      Profile: { ...editedItem.Profile, Gear: newGear },
                    });
                  }}
                />
              </>)
              }
              < TextField
                fullWidth
                multiline
                maxRows={5}
                sx={{
                  marginTop: "10px",
                }}
                label="Description"
                value={editedItem?.Description || ""}
                onChange={(e) =>
                  setEditedItem({ ...editedItem, Description: e.target.value })
                }
              />
              <Button onClick={() => handleSaveItem(editedItem, index)} className="no-print">
                Save
              </Button>
            </Paper>
          </Modal >
        )}
      </Paper >
    ));
  }

  const getWeapon = (key) => {
    return weapons.find((w) => w.Key === key);
  };

  const WeaponPanel = (weaponObject) => {
    let vehicleWeapon = weaponObject.weapon || weaponObject.VehicleWeaponProperties;
    let weapon = getWeapon(vehicleWeapon.Key) || vehicleWeapon;
    if (weapon.VehicleWeaponProperties) { vehicleWeapon = vehicleWeapon.VehicleWeaponProperties }

    const qualitiesList = qualitiesString(
      weapon.Qualities ? weapon.Qualities : "",
      vehicleWeapon.Qualities,
    );

    return (
      <ListItem dense>
        <Paper variant="outlined" style={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h9">{weapon.Name}</Typography>{" "}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption">
                Fire Arc:{" "}
                {Object.entries(vehicleWeapon.FiringArcs ?? {})
                  .filter(([arcName, value]) => value === "true")
                  .map(([arcName]) => arcName)
                  .join(", ")}
              </Typography>
              <br />
              <Typography variant="caption">Damage: {weapon.Damage}</Typography>
              <br />
              <Typography variant="caption">Crit: {weapon.Crit}</Typography>
              <br />
              <Typography variant="caption">Range: {weapon.Range}</Typography>
              <br />
              {(weapon.Qualities ||
                (vehicleWeapon.Qualities && qualitiesList)) && (
                  <Typography variant="caption">{qualitiesList || weapon.Qualities}</Typography>
                )}
            </Grid>
          </Grid>
        </Paper>
      </ListItem>
    );
  };

  function VehicleDetails({ array }) {
    return array.map((preview, index) => (
      <Paper
        key={preview.Key + index}
        variant="outlined"
        style={{ minHeight: "20vh" }}
      >
        <Typography variant="h5" style={{ padding: "15px" }}>
          {preview.Name}
        </Typography>
        {preview.Name && (
          <Grid container>
            <Grid item xs={12} sm={6}>
              <List>
                {preview.Price && (
                  <ListItem>{`Price: ${preview.Price} credits`}</ListItem>
                )}
                {preview.Rarity && (
                  <ListItem>{`Rarity: ${preview.Rarity}${preview.Restricted ? ", Restricted " : ""
                    }`}</ListItem>
                )}
                <ListItem>{`Sensor Range: ${preview.SensorRange || "none"
                  }`}</ListItem>
                {preview.NaviComputer && (
                  <ListItem>{`Navicomputer: ${preview.NaviComputer ? "Yes" : "No"
                    }`}</ListItem>
                )}
                {preview.HyperdrivePrimary && (
                  <ListItem>
                    {`Hyperdrive: Primary Class ${preview.HyperdrivePrimary}${preview.HyperdriveBackup
                      ? ", Backup Class " + preview.HyperdriveBackup
                      : ""
                      }`}
                  </ListItem>
                )}
                {preview.StarFighters && (
                  <ListItem>{`Starfighters: ${preview.StarFighters}`}</ListItem>
                )}
                {preview.Crew && <ListItem>{`Crew: ${preview.Crew}`}</ListItem>}
                {preview.Passengers && (
                  <ListItem>{`Passengers: ${preview.Passengers}`}</ListItem>
                )}
                {preview.EncumbranceCapacity && (
                  <ListItem>{`Encumbrance Capacity: ${preview.EncumbranceCapacity}`}</ListItem>
                )}
                {preview.Consumables && (
                  <ListItem>{`Consumables: ${preview.Consumables}`}</ListItem>
                )}
                {preview.Massive && (
                  <ListItem>{`Massive: ${preview.Massive}`}</ListItem>
                )}
                <ListItem>{`Hard Points: ${preview.HP || 0}`}</ListItem>
                <ListItem>{preview.Description}</ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Table
                size="small"
                sx={{
                  "& .MuiTableCell-root": { textAlign: "center" },
                }}
              >
                <TableRow>
                  <TableCell>Armor</TableCell>
                  <TableCell>Hull Trauma</TableCell>
                  <TableCell>System Strain</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">{`${preview.Armor || 0
                      }`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{`${preview.HullTrauma || 0
                      }`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{`${preview.SystemStrain || 0
                      }`}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">{`${preview.Silhouette || 0
                      }`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{`${preview.Speed || 0
                      }`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{`${preview.Handling || 0
                      }`}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Silhouette </TableCell>
                  <TableCell>Speed </TableCell>
                  <TableCell>Handling </TableCell>
                </TableRow>
              </Table>
              <Typography style={{ display: "flex", justifyContent: "center" }}>
                Defense Fore|Port|Starboard|Aft:
              </Typography>
              <Typography style={{ display: "flex", justifyContent: "center" }}>
                {preview.DefFore || 0} | {preview.DefPort || "-"} |{" "}
                {preview.DefStarboard || "-"} | {preview.DefAft || 0}
              </Typography>
              {preview.BaseMods && preview.BaseMods.map((mod) => (
                <div
                  style={{ padding: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(mod.MiscDesc),
                  }}
                />
              ))}
              {preview.VehicleWeapons && (
                <List>
                  {Array.isArray(preview.VehicleWeapons) ? (
                    preview.VehicleWeapons.map(
                      (weapon, index) => (
                        <WeaponPanel key={weapon.Key + index} weapon={weapon} />
                      ),
                    )
                  ) : (
                    <WeaponPanel
                      weapon={preview.VehicleWeapons}
                    />
                  )}
                </List>
              )}
              {preview.WeaponModifiers?.map(
                (weapon, index) => (
                  <WeaponPanel key={index} weapon={weapon} />
                ),
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    ));
  }

  const setCurrentStrain = (value) => {
    if (isNaN(value)) {
      return;
    }
    setStrainCurrent(value)
    handleCharacterChange({ StrainCurrent: value })
  }

  const setCurrentWounds = (value) => {
    if (isNaN(value)) {
      return;
    }
    setWoundsCurrent(value)
    handleCharacterChange({ WoundsCurrent: value })
  }

  const expandedWeapons = [];

  character.Weapons.forEach((weapon) => {
    expandedWeapons.push(weapon);

    if (weapon.Attachments && Array.isArray(weapon.Attachments)) {
      weapon.Attachments.forEach((attachment) => {
        if (attachment.WeaponModifiers && Array.isArray(attachment.WeaponModifiers)) {
          expandedWeapons.push(...attachment.WeaponModifiers);
        }
      });
    }
  });

  ['Armor', 'Gear'].forEach((category) => {
    character[category]?.forEach((item) => {
      if (item.WeaponModifiers && Array.isArray(item.WeaponModifiers)) {
        for (let w in item.WeaponModifiers) {
          let weap = item.WeaponModifiers[w]
          if (!weap.Name) { weap.Name = item.Name }
          expandedWeapons.push(weap)
        }
      }

      if (item.Attachments && Array.isArray(item.Attachments)) {
        item.Attachments.forEach((attachment) => {
          if (attachment.WeaponModifiers && Array.isArray(attachment.WeaponModifiers)) {
            expandedWeapons.push(...attachment.WeaponModifiers);
          }
        });
      }
    });
  });

  return (
    <Container>
      <Paper
        variant="outlined"
        style={{ display: "flex", alignItems: "center", padding: "16px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField label="Name"
              value={characterName}
              onChange={changeName}
              sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              label="Species"
              value={speciesName}
              onChange={changeSpeciesName}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={4.5} sm={4.5} md={2} lg={2}>
            <Typography>
              {`Career: `}
            </Typography>
            <Typography>
              {`${character.Career.Name || ""}`}
            </Typography>
          </Grid>
          <Grid item xs={4.5} sm={4.5} md={2} lg={2}>
            <Typography>
              {`Specialization${character.Specializations[1] ? "s:\u00A0" : ":\u00A0"}`}
            </Typography>
            <Typography>
              {character.Specializations.map((spec) => (<>
                {spec.Name}
                {spec !==
                  character.Specializations[
                  character.Specializations.length - 1
                  ] && ", "}{" "}
              </>))}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <div style={{ marginLeft: "auto" }}>
              {" "}
              <Button color="primary" onClick={handleSave} className="no-print">
                Save
              </Button>
              <FormControl variant="standard">
                <input
                  id="load-character"
                  type="file"
                  onChange={handleLoad}
                  hidden
                />
                <Button
                  color="primary"
                  onClick={() => document.getElementById("load-character").click()}
                  className="no-print"
                >
                  Load
                </Button>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Grid container >
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Brawn" value={characteristics.Brawn || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Agility" value={characteristics.Agility || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Intellect" value={characteristics.Intellect || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Cunning" value={characteristics.Cunning || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Willpower" value={characteristics.Willpower || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <StatForm title="Presence" value={characteristics.Presence || '-'} color="red" sizes={["h5", "h3"]} />
        </Grid>
      </Grid>

      <Paper variant="outlined">
        <Grid container sx={{ textAlign: "center", alignItems: "center", padding: "8px" }}>
          <Grid item xs={12} sm={12} lg={1}>
            <StatForm title="Soak" value={character.Soak || '-'} color="blue" sizes={["h5", "h4"]} />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="h5">Wounds</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Paper sx={{ margin: "10px" }}>
                  <StatForm title="Current" color="blue" />
                  <TextField
                    value={woundsCurrent}
                    onChange={(e) => setCurrentWounds(e.target.value)}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        fontSize: ' 2.125rem',
                        padding: '0px',
                        height: '2.625rem',
                      }
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <StatForm title="Threshold" value={character.Wounds || '-'} color="blue" sizes={["h7", "h4"]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Typography variant="h5">Strain</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Paper sx={{ margin: "10px" }}>
                  <StatForm title="Current" color="blue" />
                  <TextField
                    value={strainCurrent}
                    onChange={(e) => setCurrentStrain(e.target.value)}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        fontSize: ' 2.125rem',
                        padding: '0px',
                        height: '2.625rem',
                      }
                    }} />
                </Paper>
              </Grid>
              <Grid item xs={6} >
                <StatForm title="Threshold" value={character.Strain || "-"} color="blue" sizes={["h7", "h4"]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container>
              <Grid item xs={6} sm={6} lg={6}>
                <Typography variant="h5">Defense</Typography>
                <Grid container>
                  <Grid item xs={6} >
                    <StatForm title="Ranged" value={Math.min(defense.Ranged || 0, 4).toString()} color="blue" sizes={["h7", "h4"]} />
                  </Grid>
                  <Grid item xs={6} >
                    <StatForm title="Melee" value={Math.min(defense.Melee || 0, 4).toString()} color="blue" sizes={["h7", "h4"]} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Typography variant="h5">Encumbrance</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <StatForm title="Current" value={encumbranceCurrent || '-'} color="blue" sizes={["h7", "h4"]} />
                  </Grid>
                  <Grid item xs={6}>
                    <StatForm title="Threshold" value={encumbrance || '-'} color="blue" sizes={["h7", "h4"]} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <SkillsTable style={{ padding: "16px", marginTop: "4px" }} />


      <Container
        style={{
          display: "flex",
          padding: "8px",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography>Weapons</Typography>
      </Container>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Skill</TableCell>
              <TableCell align="center">Dice Pool</TableCell>
              <TableCell>Range</TableCell>
              <TableCell align="center">Dam</TableCell>
              <TableCell align="center">Crit</TableCell>
              <TableCell>Qualities</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {speciesWeapon && speciesWeapon.Secondary && (<TableRow key={'speciesWeapon'}>
              <TableCell>{speciesWeapon?.UnarmedName || "Unarmed"}</TableCell>
              <TableCell>{character.Skills.find((s) => s.Key === (speciesWeapon?.SkillKey || 'BRAWL')).skill}</TableCell>
              <TableCell align="center">
                {getDicePool(
                  character,
                  character.Skills.find((s) => s.Key === (speciesWeapon?.SkillKey || 'BRAWL'))
                    .value || 0,
                  characteristics[
                  character.Skills.find(
                    (s) => s.Key === (speciesWeapon?.SkillKey || 'BRAWL'),
                  ).characteristic
                  ],
                  getTotalRanks(character.Skills.find((s) => s.Key === (speciesWeapon?.SkillKey || 'BRAWL'))),
                  character.Skills.find((s) => s.Key === (speciesWeapon?.SkillKey || 'BRAWL')),
                  dieModifiers
                )}
              </TableCell>
              <TableCell>{speciesWeapon?.Range || "Engaged"}</TableCell>
              <TableCell align="center">{speciesWeapon?.Damage || (speciesWeapon?.DamageAdd ? ("+" + speciesWeapon.DamageAdd) : "+0")}</TableCell>
              <TableCell align="center">
                <div
                  style={{ padding: "15px" }}
                  dangerouslySetInnerHTML={{
                    __html: MarkedText.renderer(`${critSymbols(speciesWeapon?.Crit || 5)}`,
                    ),
                  }}
                />
              </TableCell>
              <TableCell>
                {qualitiesString(speciesWeapon?.Qualities ? speciesWeapon?.Qualities : [{ Key: 'KNOCKDOWN' }, { Key: 'STUNSETTING' }])}
              </TableCell>
            </TableRow>)}
            {expandedWeapons.map((weapon, index) => (
              <TableRow key={weapon.Key + index}>
                <TableCell>{weapon.Name}</TableCell>
                <TableCell>
                  {
                    character.Skills.find((s) => s.Key === weapon.SkillKey)
                      .skill
                  }
                </TableCell>
                <TableCell align="center">
                  {getDicePool(
                    character,
                    characteristics[
                    character.Skills.find(
                      (s) => s.Key === weapon.SkillKey,
                    ).characteristic
                    ],
                    getTotalRanks(character.Skills.find((s) => s.Key === weapon.SkillKey)) || 0,
                    character.Skills.find((s) => s.Key === weapon.SkillKey),
                    dieModifiers,
                    weapon
                  )}
                </TableCell>
                <TableCell>{weapon.Range}</TableCell>
                <TableCell align="center">
                  {weapon.Damage != null
                    ? weapon.Damage
                    : weapon.DamageAdd
                      ? "+" + weapon.DamageAdd
                      : "+0"}
                </TableCell>
                <TableCell align="center">
                  <div
                    style={{ padding: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: MarkedText.renderer(`${critSymbols(weapon.Crit)}`,
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>
                  {qualitiesString(weapon.Qualities)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={3} sm={3}>
          <StatForm
            title="Starting XP"
            value={(parseInt(character.Species.StartingAttrs.Experience || 0) +
              parseInt(character.Obligations[0].XP5 ? 5 : 0) +
              parseInt(character.Obligations[0].XP10 ? 10 : 0) +
              parseInt(character.Duties[0].XP5 ? 5 : 0) +
              parseInt(character.Duties[0].XP10 ? 10 : 0) +
              parseInt(character.Morality.XPC ? 5 : 0) +
              parseInt(character.Morality.XP10 ? 10 : 0)) || "0"}
            color="blue"
            sizes={["h6", "h5"]}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Paper sx={{ margin: "10px" }}>
            <StatForm title="Earned XP" color="blue" sizes={["h6", "h5"]} />
            <TextField
              size="small"
              fullWidth
              inputMode="numeric"
              value={earnedXP}
              onChange={earnedChange}
              inputProps={{
                style: {
                  textAlign: 'center',
                  fontSize: ' 1.5rem',
                  padding: '0px',
                  height: '2rem',
                }
              }}
              sx={{ input: { textAlign: "center" } }}
            />
          </Paper>
        </Grid>
        <Grid item xs={3} sm={3}>
          <StatForm title="Available XP" value={character.XP || "0"} color="blue" sizes={["h6", "h5"]} />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Paper sx={{ margin: "10px" }}>
            <StatForm title="Credits" color="blue" sizes={["h6", "h5"]} />
            <TextField
              size="small"
              fullWidth
              inputMode="numeric"
              value={character.Credits}
              onChange={(event) => {
                if (isNaN(event.target.value)) {
                  return;
                }
                handleCharacterChange({ Credits: event.target.value });
              }}
              inputProps={{
                style: {
                  textAlign: 'center',
                  fontSize: ' 1.5rem',
                  padding: '0px',
                  height: '2rem',
                }
              }}
              sx={{ input: { textAlign: "center" } }}
            />
          </Paper>
        </Grid>
      </Grid>

      {boughtTalents && boughtTalents[0] && <TalentsTable />}
      {boughtSignatureAbilities()[0] && <SignatureAbilitiesTable />}
      {character.ForceRating >= 1 && <ForceTable />}

      <Grid container>
        <Grid item xs={12} sm={5}>
          <Card
            style={{
              maxHeight: "500px",
              maxWidth: "500px",
              minHeight: "20px",
              minWidth: "200px",
              display: "flex",
              padding: "16px",
            }}
          >
            <CardMedia
              component="img"
              image={imageUrl}
              alt="Character Image"
              style={{ objectFit: "contain" }}
            />
          </Card>
          <TextField
            fullWidth
            label="Image URL"
            variant="outlined"
            value={imageUrl}
            onChange={handleImageChange}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <div style={{ padding: "16px" }}>
            <Typography variant="h5">Background</Typography>
            <Grid container spacing={2}>
              {character.Background.Culture.Name && (
                <Grid item xs={12} sm={4}>
                  {character.Background.Culture.Name}
                </Grid>
              )}
              {character.Background.Force.Name && (
                <Grid item xs={12} sm={4}>
                  {character.Background.Force.Name}
                </Grid>
              )}
              {character.Background.Adventure.Name && (
                <Grid item xs={12} sm={4}>
                  {character.Background.Adventure.Name}
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Story"
                  multiline
                  style={{ height: "auto" }}
                  value={textEntry}
                  onChange={handleTextEntryChange}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        {character.Motivations[0].Name ||
          (character.Motivations[0].Text && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">Motivation</Typography>
              <Paper variant="outlined">
                {character.Motivations.map((motivation, index) => (
                  <div key={index}>
                    <Typography variant="h6">
                      {motivation.Motivation && motivation.Motivation.Name}
                      {motivation.SpecificMotivation.Name &&
                        ", " + motivation.SpecificMotivation.Name}
                    </Typography>
                    <Typography>{motivation.Text}</Typography>
                  </div>
                ))}
              </Paper>
            </Grid>
          ))}
        {(character.Obligations[0].Text || character.Obligations[0].Name) && (
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">Obligation</Typography>
            {character.Obligations.map((obligation, index) => (
              <Paper key={index} variant="outlined">
                <Typography variant="h6">{obligation.Name}</Typography>
                <Typography>{obligation.Text}</Typography>
                <Grid container>
                  <Grid item>
                    <Typography>Score:</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      inputMode="numeric"
                      value={character.Obligations[index].Total}
                      onChange={(event) => obligationChange(event, index)}
                      sx={{ input: { textAlign: "center", padding: "2px" }, paddingLeft: "5px", width: "100px" }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))}
            <Typography>Total: {character.Obligations.reduce((sum, item) => sum + (item.Total || 0), 0)}</Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {(character.Duties[0].Text || character.Duties[0].Name) && (
            <>
              <Typography variant="h5">Duty</Typography>
              {character.Duties.map((duty, index) => (
                <Paper key={index} variant="outlined">
                  <Typography variant="h6">{duty.Name}</Typography>
                  <Typography>{duty.Text}</Typography>
                  <Grid container>
                    <Grid item>
                      <Typography>Score:</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        size="small"
                        inputMode="numeric"
                        value={character.Duties[index].Total}
                        onChange={(event) => dutyChange(event, index)}
                        sx={{ input: { textAlign: "center", padding: "2px" }, paddingLeft: "5px", width: "100px" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Typography>Total: {character.Duties[0].Total}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {(character.Morality.StrengthWeakness[0].Strength.Name ||
            character.Morality.StrengthWeakness[0].Weakness.Name) && (
              <>
                <Typography variant="h5">Morality</Typography>
                {character.Morality.StrengthWeakness.map((pair, index) => (
                  <div key={index}>
                    <Grid container>
                      <Grid xs={12} sm={6}>
                        <Paper variant="outlined">
                          <Typography variant="h6">
                            {pair.Strength.Name}
                          </Typography>
                          <Typography>{pair.Strength.Text}</Typography>
                        </Paper>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <Paper variant="outlined">
                          <Typography variant="h6">
                            {pair.Weakness.Name}
                          </Typography>
                          <Typography>{pair.Weakness.Text}</Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <Grid container>
                  <Grid item>
                    <Typography>Score:</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      inputMode="numeric"
                      value={character.Morality.Score}
                      onChange={(event) => moralityChange(event)}
                      sx={{ input: { textAlign: "center", padding: "2px" }, paddingLeft: "5px", width: "100px" }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
      {(character.Weapons[0] || character.Armor[0] || character.Gear[0]) && (
        <Typography variant="h4" style={{ textAlign: "center" }}>
          Equipment
        </Typography>
      )}
      <GearDetails array={character.Weapons} category="Weapons" />
      <GearDetails array={character.Armor} category="Armor" />
      <GearDetails array={character.Gear} category="Gear" />
      {character.Vehicles[0] && (
        <Typography variant="h4" style={{ textAlign: "center" }}>
          Vehicles
        </Typography>
      )}
      <VehicleDetails array={character.Vehicles} />
      {(character.Mentor.Description || character.Mentor.Training || character.Mentor.CW || character.Mentor.Purge || character.Mentor.Personality || character.Mentor.Complication || character.Mentor.ForceTradition) && (
        <Paper variant="outlined">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            Mentor
          </Typography>
          <Typography>{character.Mentor.Description}</Typography>
          <Typography>
            {character.Mentor.Training
              ? character.Mentor.Training.Title + ", "
              : ""}{" "}
            {character.Mentor.CW ? character.Mentor.CW.Title + ", " : ""}{" "}
            {character.Mentor.Purge ? character.Mentor.Purge.Title + ", " : ""}
            {character.Mentor.Personality
              ? character.Mentor.Personality.Style + ", "
              : ""}
            {character.Mentor.Complication
              ? character.Mentor.Complication.Complication + ", "
              : ""}
          </Typography>
          {character.Mentor.ForceTradition && <>
            <Typography>{character.Mentor.ForceTradition.Tradition}</Typography>
            <Typography>{character.Mentor.ForceTradition.Benefit.Description}</Typography>
            {!character.Mentor.ForceTradition.Drawback.Removed && <Typography>{character.Mentor.ForceTradition.Drawback.Description}</Typography>}
          </>}
        </Paper>
      )}
      {character.Holocron[0] && (
        <Paper variant="outlined">
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Holocron
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            {character.Holocron[0].skill}, {character.Holocron[1].skill}
          </Typography>
        </Paper>
      )}
      <Grid container>
        {character.Ally && (
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Ally
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                {character.Ally.Name}
              </Typography>
              <div
                style={{ padding: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(warriorRewards.Allies.Benefit + character.Ally.Dice),
                }}
              />
            </Paper>
          </Grid>
        )}
        {character.Apprentice && (
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Apprentice
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                {character.Apprentice.Name}
              </Typography>
              <div
                style={{ padding: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(character.Apprentice.Benefit),
                }}
              />
            </Paper>
          </Grid>
        )}
        {character.Follower && (
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Follower
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                {character.Follower.Name}
              </Typography>
              <div
                style={{ padding: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(warriorRewards.Followers.Benefit + character.Follower.Dice),
                }}
              />
            </Paper>
          </Grid>
        )}
        {character.School && (
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                School
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                {character.School.Name}
              </Typography>
              <div
                style={{ padding: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(character.School.Benefit),
                }}
              />
            </Paper>
          </Grid>
        )}
        {character.Title?.Type && character.Title?.Tier && (
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Title
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                {character.Title.Tier.Name.replace("Title", character.Title.Type.Name)}
              </Typography>
              <div
                style={{ padding: "5px" }}
                dangerouslySetInnerHTML={{
                  __html: MarkedText.renderer(character.Title.Tier.Benefit.replace("applicable Social skill", character.Title.Type.Skills)),
                }}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Typography>Notes</Typography>
      <TextField fullWidth multiline value={notes} onChange={(e) => updateNotes(e.target.value)} />
    </Container>
  );
}

export default App;
