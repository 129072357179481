import React, { useState, useEffect } from 'react';
import {
    Modal, Box, Typography, Button, Select, MenuItem,
    TextField, Paper
} from '@mui/material';
import skills from "../data/skills.json"

const FilterModal = ({ open, handleClose, filter, setFilter, category, data }) => {
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [selectedSkill, setSelectedSkill] = useState('');
    const [minRange, setMinRange] = useState('');
    const [maxRange, setMaxRange] = useState('');
    const [minDamage, setMinDamage] = useState('');
    const [maxDamage, setMaxDamage] = useState('');
    const [minCrit, setMinCrit] = useState('');
    const [maxCrit, setMaxCrit] = useState('');
    const [minSoak, setMinSoak] = useState('');
    const [maxSoak, setMaxSoak] = useState('');
    const [minDefense, setMinDefense] = useState('');
    const [maxDefense, setMaxDefense] = useState('');
    const [minEncumbrance, setMinEncumbrance] = useState('');
    const [maxEncumbrance, setMaxEncumbrance] = useState('');
    const [minSilhouette, setMinSilhouette] = useState('');
    const [maxSilhouette, setMaxSilhouette] = useState('');
    const [minSpeed, setMinSpeed] = useState('');
    const [maxSpeed, setMaxSpeed] = useState('');
    const [minArmor, setMinArmor] = useState('');
    const [maxArmor, setMaxArmor] = useState('');

    useEffect(() => {
        setMinPrice('');
        setMaxPrice('');
        setSelectedSkill('');
        setMinRange('');
        setMaxRange('');
        setMinDamage('');
        setMaxDamage('');
        setMinCrit('');
        setMaxCrit('');
        setMinSoak('');
        setMaxSoak('');
        setMinDefense('');
        setMaxDefense('');
        setMinEncumbrance('');
        setMaxEncumbrance('');
        setMinSilhouette('');
        setMaxSilhouette('');
        setMinSpeed('');
        setMaxSpeed('');
        setMinArmor('');
        setMaxArmor('');
        setFilter('')
    }, [category]);

    let skillsAvailable = []
    if (data && data.length > 0) {
        const uniqueSkillKeys = new Set();

        data.forEach(item => {
            if (item.SkillKey) {
                uniqueSkillKeys.add(item.SkillKey);
            }
        });
        skillsAvailable = Array.from(uniqueSkillKeys);
    }

    const rangeValues = ["Engaged", "Short", "Medium", "Long", "Extreme"];

    const applyFilters = () => {
        const filterFunction = (item) => {
            const rangeValues = ["Engaged", "Short", "Medium", "Long", "Extreme"];
            const getRangeIndex = (range) => rangeValues.indexOf(range);

            const checkRange = (value, min, max) => {
                const numValue = parseInt(value);
                const minValue = parseInt(min);
                const maxValue = parseInt(max);
                return (!minValue || (numValue >= minValue)) && (!maxValue || (numValue <= maxValue));
            };

            const checkCustomRange = (value, min, max) => {
                const rangeIndex = getRangeIndex(value);
                const minIndex = getRangeIndex(min);
                const maxIndex = getRangeIndex(max);
                return ((!min || (rangeIndex >= minIndex)) && (!max || (rangeIndex <= maxIndex)));
            };

            return (
                checkRange(item.Price, minPrice, maxPrice) &&
                (!selectedSkill || item.SkillKey === selectedSkill) &&
                (category !== "Weapons" || (category === "Weapons" &&
                    checkCustomRange(item.Range, minRange, maxRange) &&
                    checkRange(item.Damage, minDamage, maxDamage) &&
                    checkRange(item.Crit, minCrit, maxCrit))) &&
                (category !== "Armor" || (category === "Armor" &&
                    checkRange(item.Soak, minSoak, maxSoak) &&
                    checkRange(item.Defense, minDefense, maxDefense))) &&
                ((category !== "Vehicles" &&
                    checkRange(item.Encumbrance, minEncumbrance, maxEncumbrance)) ||
                    (category === "Vehicles" &&
                        checkRange(item.Silhouette, minSilhouette, maxSilhouette) &&
                        checkRange(item.Speed, minSpeed, maxSpeed) &&
                        checkRange(item.Armor, minArmor, maxArmor)))
            );
        };

        setFilter(() => filterFunction);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                overflow: "auto"
            }}>
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Typography>Price:</Typography>
                <TextField label="Min" value={minPrice} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinPrice(e.target.value) : ""} />
                <TextField label="Max" value={maxPrice} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxPrice(e.target.value) : ""} />

                {category === "Weapons" && <>
                    <Typography>Skill</Typography>
                    <Select value={selectedSkill} onChange={(e) => setSelectedSkill(e.target.value)} sx={{ minWidth: "100px" }}>
                        <MenuItem value="">None</MenuItem>
                        {skills.filter(s => skillsAvailable.includes(s.Key) || s.Key === selectedSkill).map(skill => (
                            <MenuItem key={skill.Key} value={skill.Key}>
                                {skill.skill}
                            </MenuItem>
                        ))}
                    </Select>

                    <Typography>Range</Typography>
                    <Select value={minRange} onChange={(e) => setMinRange(e.target.value)} sx={{ minWidth: "100px" }}>
                        <MenuItem value="">None</MenuItem>
                        {rangeValues.map(range => (
                            <MenuItem key={range} value={range}>{range}</MenuItem>
                        ))}
                    </Select>
                    <Select value={maxRange} onChange={(e) => setMaxRange(e.target.value)} sx={{ minWidth: "100px" }}>
                        <MenuItem value="">None</MenuItem>
                        {rangeValues.map(range => (
                            <MenuItem key={range} value={range}>{range}</MenuItem>
                        ))}
                    </Select>

                    <Typography>Damage:</Typography>
                    <TextField label="Min" value={minDamage} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinDamage(e.target.value) : ""} />
                    <TextField label="Max" value={maxDamage} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxDamage(e.target.value) : ""} />

                    <Typography>Crit:</Typography>
                    <TextField label="Min" value={minCrit} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinCrit(e.target.value) : ""} />
                    <TextField label="Max" value={maxCrit} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxCrit(e.target.value) : ""} />
                </>}

                {category === "Armor" && <>
                    <Typography>Soak</Typography>
                    <TextField label="Min" value={minSoak} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinSoak(e.target.value) : ""} />
                    <TextField label="Max" value={maxSoak} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxSoak(e.target.value) : ""} />
                    <Typography>Defense:</Typography>
                    <TextField label="Min" value={minDefense} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinDefense(e.target.value) : ""} />
                    <TextField label="Max" value={maxDefense} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxDefense(e.target.value) : ""} />
                </>}

                {category !== "Vehicles" && <>
                    <Typography>Encumbrance:</Typography>
                    <TextField label="Min" value={minEncumbrance} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinEncumbrance(e.target.value) : ""} />
                    <TextField label="Max" value={maxEncumbrance} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxEncumbrance(e.target.value) : ""} />
                </>}

                {category === "Vehicles" && <>
                    <Typography>Silhouette</Typography>
                    <TextField label="Min" value={minSilhouette} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinSilhouette(e.target.value) : ""} />
                    <TextField label="Max" value={maxSilhouette} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxSilhouette(e.target.value) : ""} />
                    <Typography>Speed:</Typography>
                    <TextField label="Min" value={minSpeed} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinSpeed(e.target.value) : ""} />
                    <TextField label="Max" value={maxSpeed} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxSpeed(e.target.value) : ""} />
                    <Typography>Armor:</Typography>
                    <TextField label="Min" value={minArmor} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMinArmor(e.target.value) : ""} />
                    <TextField label="Max" value={maxArmor} onChange={(e) => (!isNaN(parseInt(e.target.value)) || e.target.value === "") ? setMaxArmor(e.target.value) : ""} />
                </>}
                <br />
                <Button onClick={applyFilters}>Apply Filters</Button>
            </Box>
        </Modal>
    );
};

export default FilterModal;
